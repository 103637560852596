import {Button, Modal , Box, Typography} from '@mui/material';
import SnackBar1 from './snackBar';
import {useState} from 'react';
import axios from 'axios';
import {url} from '../constants';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from 'react';



let message = null, color = null

let limit = null;

const BookSlotModal = (props)=>{
    const [show, setShow] = useState(false);
    const handleClose = ()=>props.module(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      const [age, setAge] = useState('');
      const [doctor, setDoctor] = useState(null)

      useEffect(()=>{
        var config = {
          method: 'get',
          url: `${url}doctors/`,
          headers: { 
          }
        };
        
        axios(config)
        .then(function (response) {
          setDoctor(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
        
      },[])

      const handleChange = (event) => {
        setAge(event.target.value);
        for(var i = 0; i < props.doctor_list.length; i++ ){
          if(Object.keys(props.doctor_list[i])[0] === event.target.value){
            limit =  props.doctor_list[i][Object.keys(props.doctor_list[i])[0]]
          }
        }
      };

    return (
        <>

        <SnackBar1 message={message} color={color} show={show} module={setShow}/>
         <Modal
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
    <Box sx={style}>
        <Typography fontWeight={"bold"} my={2} id="modal-modal-title" variant="h5" component="h2">
        Here are your Slot Details:
        </Typography>
        <Typography sx={{m:2}} id="modal-modal-title" variant="body1" component="h2">
        Date : {props.date!== null?props.date.toDateString():null}
        </Typography>
      
        <Typography sx={{m:2}} id="modal-modal-title" variant="body1" component="h2">
        Slot : {props.slot}
        </Typography>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Doctor</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Doctor"
          onChange={handleChange}
        >
          {props.doctor_list.map((e)=>{
            return (
              <MenuItem value={Object.keys(e)[0]}>{Object.keys(e)[0]}</MenuItem>
            )
          })}
        
        </Select>
      </FormControl>
      {age !== ''?(
        <p>Max Limit Allowed {limit}</p>
      ) : null }
    </Box>

       
    
    <Button variant="contained" color="success" onClick={()=>{
      let college_id = null;
      for(var i = 0; i < doctor.length; i++ ){
        if(doctor[i].name === age)
        {
          college_id = doctor[i].id;
          break;
        }
      }
      var data = JSON.stringify({
        "date": props.date.toISOString(),
        "slot": props.slotid + 1,
        "doctor": college_id
      });
      
      var config = {
        method: 'post',
        url: url + 'slots/',
        headers: { 
          'Authorization': `Token ${localStorage.getItem('token')}`, 
          'Content-Type': 'application/json', 
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        props.module(false)
        message = "Slot Successfully Booked!!"
        color = "success"
        setShow(true)
        window.location.reload()
      })
      .catch(function (error) {
        props.module(false)
        message = "Slot already booked!!"
        color = "error"
        setShow(true)
        // window.location.reload()

      });
       
        }}>
         Confirm
        </Button>
        <Button variant="contained" color="error" sx={{m:2}} onClick={()=>{
            props.module(false)

        }}>
         Close
        </Button>
    </Box>
    </Modal>
        </>
    )
}

export default BookSlotModal;