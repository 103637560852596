import {Container,Typography,Button,TextField,Stack,CircularProgress} from '@mui/material';
import {useState} from 'react';
import DoctorRegisterModal from '../components/doctorRegisterModal';
import {useNavigate} from 'react-router';
import SnackBar1 from '../components/snackBar';
import axios from 'axios';
import {url} from '../constants';

let email = null, pass = null
let message = null, color = null


const DoctorLogin = ()=>{

    const [show, setShow] = useState(false);
    const [valemail, setvalemail] = useState(false)
    const [valpass, setvalpass] = useState(false)
    const [show1, setShow1] = useState(false)
    const [load, setLoad] = useState(false);

    const navigate = useNavigate()

    return (
        <>
        <SnackBar1 message={message} color={color} show={show1} module={setShow1}/>
        <DoctorRegisterModal show={show} module={setShow}/>
          <Container>
            <Typography variant="h4" m={2} fontWeight={"bold"}>Doctor Login</Typography>
        <form>
      <Stack m={2} spacing={3}>
        <TextField onChange={(e)=>email = e.target.value} error={valemail} label="Email(@thapar.edu)" type="email"/>
        <TextField onChange={(e)=>pass = e.target.value}  error={valpass} label="Password" type="password" />

       
      </Stack>
    </form>
    <Button variant="contained" onClick={()=>{
      if(email === null) setvalemail(true)
      else setvalemail(false)

      if(pass === null) setvalpass(true)
      else setvalpass(false)

      if(email !== null && pass !== null){
        setLoad(true)
        var data = JSON.stringify({
          "email": email,
          "password": pass
        });
        
        var config = {
          method: 'post',
          url: url + 'accounts/login/',
          headers: { 
            'Content-Type': 'application/json', 
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          setLoad(false)
          localStorage.setItem('type','doctor')
          localStorage.setItem('token',response.data.token)
          navigate("/searchstudent")

        })
        .catch(function (error) {
          setLoad(false)
          message = "Failure !!"
          color = "error"
          setShow1(true)
        });
        
      }


        }}>
          Submit
        </Button>
     
        <Button color="error" sx={{marginLeft:'5px'}} variant="contained" onClick={()=>{

            setShow(true)
            }}>
          Register Here
        </Button>
        <br/>
        {load?<CircularProgress sx={{m:2}}/>:null}

    </Container>
        </>
    )
}

export default DoctorLogin;