import {Typography,Container,Button,CircularProgress,Fab} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useState, useEffect} from 'react';
import {url} from '../constants';
import axios from 'axios';
import SnackBar1 from '../components/snackBar';
import {Refresh} from '@mui/icons-material';
import ApproveModal from '../components/approveModal';


let color = null, message = null
let studentid = null;
let id = null
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



  


const DispenseMedicine = ()=>{

    const [load, setLoad] = useState(false);
    const [data, setData] = useState(null);
    const [show, setShow] = useState(false);
    const [reload, setReload] = useState(false);
    const [show1, setShow1] = useState(false);


    useEffect(()=>{
    
        setLoad(true)
        var config = {
            method: 'get',
            url: url + 'prescribed',
            headers: { 
              'Authorization': `Token ${localStorage.getItem('token')}`, 
            }
          };
          
          axios(config)
          .then(function (response) {
        
            setLoad(false)
            let data1 = {}
            for(var obj of response.data){
              data1[obj.id]  = []
            }

            for(var obj of response.data){
                data1[obj.id].push({
                    name : obj.medicine,
                    quantity : obj.quantity,
                    object_id : obj.object_id,
                    content_type : obj.content_type 
                })
            }
           setData(data1)
           console.log(data1)
           message = "Successfully Loaded!!"
           color = "success"
           setShow(true)
           setReload(false)
          
          })
          .catch(function (error) {
              message = "There was some error !!"
              color = "error"
              setShow(true)
            setLoad(false)
            setReload(false)
          });
          

    },[reload])

    return (
        <>
       {data !== null?<ApproveModal show={show1} module={setShow1} module1={setReload} data={data[id]} />: null} 
        <SnackBar1 message={message} color={color} show={show} module={setShow} />
        <Typography fontWeight="bold" sx={{m:2}} variant='h4'>Approve Medicines for Students</Typography>
        <Container sx={{m:2}}>
        
        {load || data === null?<CircularProgress sx={{m:2}}/> : (
            <>
            {
              
              Object.keys(data).map((elem)=>{
                    return (
                        <>
                          <Typography fontWeight="bold" sx={{m:2}} align="left" variant='h5'>Student ID : {elem}</Typography>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
        <TableRow>
            <StyledTableCell>S No.</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Quantity</StyledTableCell>

        </TableRow>
        </TableHead>
        <TableBody>
        {data[elem].map((row,index) => (
            <StyledTableRow key={index}>
            <StyledTableCell component="th" scope="row">
                {index+1}
            </StyledTableCell>
            <StyledTableCell align="right">{row.name}</StyledTableCell>
            <StyledTableCell align="right">{row.quantity}</StyledTableCell>
        
            </StyledTableRow>
        ))}
        </TableBody>
        </Table>
        </TableContainer>
        <Button onClick={()=>{
            id = elem
            setShow1(true)
        }} variant="contained" color="error" sx={{m:2}}>Approve Request</Button>

                        </>
                    )
                })
            }
      
            </>
        )}
          <Fab 
          onClick={()=>{
            setReload(true)
          }}
          color="primary"  sx={{margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    zIndex: 100,
    position: 'fixed',}}><Refresh/></Fab>

    </Container>
        </>
    )
}

export default DispenseMedicine;