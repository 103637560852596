import { Badge,Menu,MenuItem,IconButton,CircularProgress} from '@mui/material';
import {useState,useEffect} from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import {url} from '../constants';

const NotificationMenu = ()=>{

    const [showMenu, setShowMenu] = useState(false);
    const [data,setData] = useState(null);

    const handleClose = ()=>setShowMenu(false);
    useEffect(()=>{
        var config = {
            method: 'get',
            url: `${url}medicines/below_threshold/`,
            headers: { 
              'Authorization': `Token ${localStorage.getItem('token')}`, 
            }
          };
          
          axios(config)
          .then(function (response) {
            setData(response.data)
        })
          .catch(function (error) {
            console.log(error);
          });
          
    },[])


    return (
        <>
        {
            data === null?(
                <>
                <CircularProgress />
                </>
            ) : (
                <>
                <IconButton sx={{m:2}}  onClick={()=>{
                    setShowMenu(true)
                  }}>   <Badge badgeContent={Object.keys(data).length} color="primary"><NotificationsIcon/></Badge></IconButton>
    
            <Menu
            id="basic-menu"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={showMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
              {Object.keys(data).map((elem)=>{
                  return (
                    <MenuItem onClick={handleClose}>Medicine {elem} has only {data[elem]} pcs left</MenuItem>

                  )
              })}

    
          </Menu>
                </>
            )
        }
     
      </>
    )
}

export default NotificationMenu;