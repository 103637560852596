import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SnackBar1 from '../components/snackBar';
import {useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {url} from '../constants';
import axios from 'axios';

let message = null, color = null;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const ApproveModal = (props)=>{

    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);

    const handleClose = () => props.module(false);
   return (
        <div>
        <SnackBar1 show={show} module={setShow} color={color} message={message}/>
        <Modal
          open={props.show}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to dispense the medicine of this student ? 
            </Typography>
         
            <Button onClick={()=>{
                setLoad(true)
                var data = JSON.stringify({
                  "content_type" : props.data[0].content_type,
                  "object_id": props.data[0].object_id
                  });
                  
                  var config = {
                    method: 'post',
                    url: url + 'prescribed/',
                    headers: { 
                      'Authorization': `Token ${localStorage.getItem('token')}`, 
                      'Content-Type': 'application/json', 
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                      message = "Succesfully Dispensed!!"
                      color = "success"
                    setShow(true)
                    setLoad(false)
                    props.module1(true)
                    handleClose()
                  })
                  .catch(function (error) {
                      message = "Can't Dispense those medicines!! Pls check stock"
                      color = "error"
                      setShow(true)
                      setLoad(false)
                      handleClose()
                    
                  });
            }} sx={{m:1}} variant="contained" color="error">
              Yes
          </Button>
          <Button onClick={()=>{
              handleClose()
          }} sx={{m:1}} variant="contained" color="primary">
              No
          </Button>
          <br/>
          {load?<CircularProgress sx={{m:2}}/>: null}
          </Box>
         
        </Modal>
      </div>
    )
}


export default ApproveModal;