import {Typography} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useState} from 'react';
import {Container} from '@mui/material';
import {Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddDoctorSchedule from '../components/addDoctorSchedule';
import axios from 'axios';
import {useEffect} from 'react';
import {url} from '../constants';
import SnackBar1  from '../components/snackBar';
import {TextField} from '@mui/material';

let message = null, color = null;
let start_date = null, end_date = null;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  



const AdminPortal = ()=>{

    const [doctorname, setdoctorname] = useState('');
    const [show, setShow] = useState(false);
    const [doctordata, setDoctorData] = useState(null);
    const [scheduledata, setScheduleData] = useState(null);
    const [show1, setShow1] = useState(false);
    


    const handleChange = (event) => {
      setdoctorname(event.target.value);
    };


    useEffect(()=>{
        var config = {
            method: 'get',
            url: `${url}doctors/`,
            headers: { 
            }
          };
          
          axios(config)
          .then(function (response) {
            message = "Successfully Loaded!"
            color = "success"
            setShow1(true)  
            setDoctorData(response.data)
              
          })
          .catch(function (error) {
              message = "There was some error"
              color = "error"
              setShow1(true)
          });
          

    },[])

    return (
        <>
        <SnackBar1 show={show1} module={setShow1} color={color} message={message}/>
        <AddDoctorSchedule doctor={doctorname} show={show} module={setShow}/>
        <Typography fontWeight={"bold"} sx={{m:3}} variant="h4">Modify Doctor Slots</Typography>
        <Container sx={{width:300,marginLeft:'auto'}}>
        {doctordata !== null ? (
             <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Doctor Name</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={doctorname}
               label="doctorname"
               onChange={handleChange}
             >
                 {doctordata.map((elem)=>{
                     return (
                         <MenuItem value={elem.id}>{elem.name}</MenuItem>
                     )
                 })}
           
             </Select>
             <Button onClick={()=>{
                 var config = {
                    method: 'get',
                    url: `${url}doctor/duration/?college_id=${doctorname}`,
                    headers: { 'Authorization': `Token ${localStorage.getItem('token')}`, 
                    }
                  };
                  
                  axios(config)
                  .then(function (response) {
                        message = "Loaded Successfully !!"
                        color = "success"
                        setShow1(true)
                        setScheduleData(response.data)
                    })
                  .catch(function (error) {
                    message = "There was some error!!"
                    color = "error"
                    setShow1(true)
                    
                  });
             }} sx={{m:2}} variant="contained" color="error">Search</Button>
     
           </FormControl>
        ):null}
       
      </Container>
        <Container sx={{my:2}}>
            {scheduledata !== null ? (
                <>
                 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S No.</StyledTableCell>
            <StyledTableCell align="right">Start Date</StyledTableCell>
            <StyledTableCell align="right">End Date</StyledTableCell>
            <StyledTableCell align="right">Morning</StyledTableCell>
            <StyledTableCell align="right">Evening</StyledTableCell>
            <StyledTableCell align="right">Save Changes</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduledata.map((row,index) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {index + 1}
              </StyledTableCell>
              <StyledTableCell align="right"><TextField onChange={(e)=>start_date = e.target.value} type="date" defaultValue={row.start}></TextField></StyledTableCell>
              <StyledTableCell align="right"><TextField onChange={(e)=>end_date = e.target.value} type="date" defaultValue={row.end}></TextField></StyledTableCell>
              <StyledTableCell align="right">{row.morning === true?"Yes" : "No"}</StyledTableCell>
              <StyledTableCell align="right">{row.evening === true?"Yes" : "No"}</StyledTableCell>
              <StyledTableCell align="right"><Button onClick={()=>{
                  if(start_date === null) start_date = row.start;
                  if(end_date === null) end_date = row.end
                  var data = JSON.stringify({
                    "id": row.id,
                   "start": start_date,
                    "end": end_date,
                    "doctor": doctorname,
                    "morning": row.morning,
                    "evening": row.evening
                  });
                  
                  var config = {
                    method: 'put',
                    url: `${url}doctor/duration/`,
                    headers: { 
                      'Content-Type': 'application/json', 
                      'Authorization': `Token ${localStorage.getItem('token')}`
                      
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                      message = "Successfully Updated !!"
                      color = "success"
                      setShow1(true)
                  })
                  .catch(function (error) {
                      message = "There was an error!!"
                      color = "error"
                      setShow1(true)

                  });
              }} color="error" variant="contained">Save</Button></StyledTableCell>
              
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button onClick={()=>{
        setShow(true)
    }} sx={{my:2}} variant="contained" color="error">Add New Schedule</Button>
                </>
            ) : null}
     
    </Container>

        </>
    )
}

export default AdminPortal;