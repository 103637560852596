import {Container,Typography, Stack , TextField, Button, CircularProgress} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ClerkRegistrationModal from '../components/clerkRegisterModal';
import SnackBar1 from '../components/snackBar';
import {url} from '../constants';
import axios from 'axios';

let email = null, pass = null
let message = null, color = null;

const ClerkLogin = ()=>{

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [valemail, setvalemail] = useState(false);
    const [valpass, setvalpass] = useState(false);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    return (
        <>
        <SnackBar1 show={show1} module={setShow1} message={message} color={color}/>
        <ClerkRegistrationModal show={show} module={setShow}/>
               <Container>
            <Typography variant="h4" m={2} fontWeight={"bold"}>Clerk Login</Typography>
        <form>
      <Stack m={2} spacing={3}>
        <TextField error={valemail} onChange={(e)=>email = e.target.value} label="Email ID" type="email"/>
        <TextField error={valpass}  onChange={(e)=>pass = e.target.value} label="Password" type="password" />

       
      </Stack>
    </form>
   <Button variant="contained" onClick={()=>{
          
            if(email === null) setvalemail(true)
            else setvalemail(false);

            if(pass === null) setvalpass(true)
            else setvalpass(false);

          if(email !== null && pass !== null){
            setLoad(true)
            var data = JSON.stringify({
              "email": email,
              "password": pass
            });
            
            var config = {
              method: 'post',
              url: url + 'accounts/login/',
              headers: { 
                'Content-Type': 'application/json', 
              },
              data : data
            };
            
            axios(config)
            .then(function (response) {
              localStorage.setItem('token',response.data.token)
                localStorage.setItem('type','clerk')
              setLoad(false);
              navigate('/medicinestock')
              
            })
            .catch(function (error) {
                message = "Invalid Credentials !! "
                color = "error"
                setLoad(false);
                setShow1(true)
            });

          }


        }}>
          Submit
        </Button>
      
        <Button color="error" sx={{marginLeft:'5px'}} variant="contained" onClick={()=>{

            setShow(true)
            }}>
          Register Here
        </Button>
          <br/>
          {load?<CircularProgress sx={{m:2}}/> : null}
    </Container>
        </>
    )
}

export default ClerkLogin;