import {TextField,Button,FormControl,MenuItem,InputLabel,Select} from '@mui/material';
import TableCell ,{ tableCellClasses }from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography } from '@mui/material';
import {url} from '../constants';

import {useState,useEffect} from 'react';
import axios from 'axios';

import TableRow from '@mui/material/TableRow';
import SnackBar1 from '../components/snackBar';
import jsPDf from 'jspdf';
import 'jspdf-autotable';


let start_date = null, end_date = null,doctor = null;
let message = null, color = null;


function generate(temp){
  
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDf(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Doctor Case Study Report";
 
  const headers = [["Sno.", "Name of the Student","Symptoms","Referral(If any)","Roll Number","Date"]];
  const data = temp.map((row,index)=> [index+1, row.student_name, row.symptoms,row.referred,row.roll_number,new Date(row.date).toDateString()]);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  
  
  doc.text(title, marginLeft, 40);

  doc.autoTable(content);
  doc.save("report.pdf")

}





  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 17,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
   
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const StudentVisitDoctor = ()=>{
    
        const [doctorData, setDoctorData] = useState(null);
        const [data, setData] = useState(null);
        const [show1, setShow1] = useState(false);
        const [valstart,setvalstart] = useState(false);
        const [valend, setvalend] = useState(false);
        const [valdoctor, setvaldoctor] = useState(false);
        const [doctor1, setdoctor1] = useState(null)





        useEffect(()=>{
          var config = {
            method: 'get',
            url: `${url}doctors/`,
            headers: { 
            }
          };
          
          axios(config)
          .then(function (response) {
            setDoctorData(response.data)
            message = "Loaded!!!"
            color = "success"
            setShow1(true)
          })
          .catch(function (error) {
            message = "error!!"
            color = "error"
            setShow1(true)

          });
        },[])
    

        const handleChange = (event) => {
          doctor = (event.target.value);
          setdoctor1(doctor)
  
        };

return (
    <>
    <SnackBar1 show={show1} module={setShow1} color={color} message={message}/>
    <Typography variant="h4" m={2} fontWeight={"bold"}>Students visiting to Doctor</Typography>
    {doctorData !== null?(
      <>
       <TextField error={valstart} onChange={(e)=>start_date = e.target.value} type="date" sx={{m:2}} label="Start Date" InputLabelProps={{ shrink: true }}/>
    <TextField error={valend} onChange={(e)=>end_date = e.target.value} type="date" sx={{m:2}} label="End Date" InputLabelProps={{ shrink: true }}/>
    <FormControl sx={{width:'180px',m:2}}>
  <InputLabel id="demo-simple-select-label">Doctor</InputLabel>
  <Select
  error={valdoctor}
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={doctor1}
    label="Doctor"

    onChange={handleChange}
  >
    {doctorData.map((elem)=>{
      return (<MenuItem value={elem.id}>{elem.name}</MenuItem>)
    })}

  </Select>
</FormControl>
    <Button variant="contained" color="error" sx={{m:2}} onClick={()=>{
      if(start_date === null) setvalstart(true)
      else setvalstart(false);

      if(end_date === null) setvalend(true)
      else setvalend(false);

      if(doctor === null) setvaldoctor(true)
      else setvaldoctor(false);



      if(start_date !== null && end_date !== null && doctor !== null){      
      var config = {
        method: 'get',
        url: `${url}studenttodoc/?start_date=${new Date(start_date).toISOString()}&end_date=${new Date(end_date).toISOString()}&doctor_id=${doctor}`,
        headers: { 
        }
      };

      axios(config)
      .then(function (response) {
        setData(response.data)
        message = "Details Loaded !!"
        color = "success"
        setShow1(true)
      })
      .catch(function (error) {
        message = "There was some Error!!"
        color = "error"
        setShow1(true)
      });
      }

    }}>Search</Button>
      </>
    ):null} 
   
    {data !== null?(
           <Container sx={{my:2}}>
           <TableContainer component={Paper}>
         <Table sx={{ minWidth: 700 }} aria-label="customized table">
           <TableHead>
             <TableRow>
               <StyledTableCell >S No.</StyledTableCell>
               <StyledTableCell align="right" sx={{fontSize:'19px'}}>Name of Student</StyledTableCell>
               <StyledTableCell align="right" sx={{fontSize:'19px'}}>Problem Diagnosed</StyledTableCell>
               <StyledTableCell align="right" sx={{fontSize:'19px'}}>Referral (If any)</StyledTableCell>
               <StyledTableCell align="right" sx={{fontSize:'19px'}}>Roll Number</StyledTableCell>
               <StyledTableCell align="right" sx={{fontSize:'19px'}}>Date</StyledTableCell>

            
             </TableRow>
           </TableHead>
           <TableBody>
             {data.map((row,index) => (
               <StyledTableRow key={index}>
                 <StyledTableCell component="th" scope="row">
                   {index + 1}
                 </StyledTableCell>
                 <StyledTableCell align="right" sx={{fontSize:'15px'}}>{row.student_name}</StyledTableCell>
                 <StyledTableCell align="right" sx={{fontSize:'15px'}}>{row.symptoms}</StyledTableCell>
                 <StyledTableCell align="right" sx={{fontSize:'15px'}}>{row.referred}</StyledTableCell>
                 <StyledTableCell align="right" sx={{fontSize:'15px'}}>{row.roll_number}</StyledTableCell>
                 <StyledTableCell align="right" sx={{fontSize:'15px'}}>{new Date(row.date).toDateString()}</StyledTableCell>


               </StyledTableRow>
             ))}
           </TableBody>
         </Table>
       </TableContainer>
       <Button sx={{m:2}} variant="contained" color="error" onClick={()=>generate(data)}>Print</Button>
       </Container>
    ) : null}
 
    </>
)
}

export default StudentVisitDoctor;