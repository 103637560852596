import {Button, Modal,Box, Typography,TextField,MenuItem,
Container} from '@mui/material';
import { useState} from 'react';
import Select from 'react-select';

const todies = [
  {
    value: 'od',
    label: 'OD (Once Daily)',
  },
  {
    value: 'hs',
    label: 'HS (At Bedtime)',
  },
  {
    value: 'bd',
    label: 'BD (Twice Daily)',
  },
  {
    value: 'tds',
    label: 'TDS (Thrice daily)',
  },
  {
    value: 'sos',
    label: 'SoS (If Needed)',
  },

];


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const AddMedicineModal = (props)=>{

    console.log(props.medicinelist)
    let newMedicineList = []
    for(var i = 0; i < props.medicinelist.length ; i++ ){
      newMedicineList.push({
        label : props.medicinelist[i].name, 
        value : props.medicinelist[i].name,
        qty : props.medicinelist[i].quantity
      })
    }

    const [name,setName] = useState(null);
    const [days, setDays] = useState(null);

    const [tod, settod] = useState(null);
    const [remarks, setRemarks] = useState("")
    const [limit, setLimit] = useState(null)
 
    const [qty, setQty] = useState(0)
    const [error, setError] = useState(false);
    

    const handleChange = (event) => {
      settod(event.target.value);

      if(days !== null){

        if(event.target.value === 'od') setQty(days)
        else if(event.target.value === 'hs') setQty(days)
        else if(event.target.value === 'bd') setQty(2*days)
        else if(event.target.value === 'tds') setQty(3*days)

        console.log(qty)
      }
    };
  

    const handleClose = ()=>props.module(false);
    return (
        <>
<Modal
  open={props.show}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography sx={{m:2}} fontWeight={"bold"} id="modal-modal-title" variant="h5" component="h2">
      Add Medicine
    </Typography>
      <Container sx={{mx: 1, my : 2}} style={{zIndex:100,position : 'relative'}}>
    <Select
        options={newMedicineList}
        onChange={(e)=>{
          setName(e.label)
          setLimit(e.qty)
        }}
      />
      </Container>
    {/* <TextField onChange={(e)=>setName(e.target.value)} sx={{m:2}} id="outlined-basic" label="Name of the Medicine" variant="outlined" /> */}
    <TextField onChange={(e)=>setDays(e.target.value)} sx={{m:2}} id="outlined-basic" label="Days" variant="outlined"  type="number"/>
    <TextField onChange={(e)=>setRemarks(e.target.value)} sx={{m:2}} id="outlined-basic" label="Remarks" variant="outlined"  type="text"/>
      <br/><br/>

     
    <TextField
          id="outlined-select-tod"
          select
          label="Select"
          value={tod}
          onChange={handleChange}
          sx={{width : '200px',m: 2}}
        >
          {todies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField value={qty} onChange={(e)=>setQty(e.target.value)} sx={{m:2}} id="outlined-basic" label="Qty" variant="outlined"  type="number"/>
          {limit !== null ? (<p style={{color:"red"}}>Max Limit Allowed : {limit}</p>): null}
          {error?(<p style={{color:"red"}}>Please Check Missing/Invalid Fields</p>): null}
    <Button onClick={()=>{

      if(name === null || days === null || tod === null || qty === null  || qty > limit){
        setError(true)
        return 
      }
        props.dataModule((prev)=>[
            ...prev,
            {
               name : name, 
               days : days, 
               time_of_the_day : tod, 
               qty : qty,
               remarks : remarks
            }
        ])
        setName(null)
        setDays(null)
        settod(null)
        setRemarks(null)
        
        handleClose()
    }} variant="contained" color="success" sx={{m:2}}>
        Save
    </Button>

  </Box>
</Modal>
        </>
    )
}

export default AddMedicineModal;