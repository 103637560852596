import {
  Typography,
  Container,
  Button,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import StudentRegisterModal from "../components/studentRegisterModal";
import { useNavigate } from "react-router";
import axios from "axios";
import SnackBar1 from "../components/snackBar";
import { url } from "../constants";

let email = null,
  password = null,
  message = null,
  color = null;

const StudentLogin = () => {
  const [show, setShow] = useState(false);
  const [valemail, setvalemail] = useState(false);
  const [valpass, setvalpass] = useState(false);
  const [load, setLoad] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <SnackBar1
        message={message}
        color={color}
        show={show1}
        module={setShow1}
      />
      <StudentRegisterModal show={show} module={setShow} />
      <Container>
        <Typography variant="h4" m={2} fontWeight={"bold"}>
          Student Login
        </Typography>
        <form>
          <Stack m={2} spacing={3}>
            <TextField
              error={valemail}
              onChange={(e) => (email = e.target.value)}
              label="Email Address (thapar.edu)"
              type="email"
            />
            <TextField
              error={valpass}
              onChange={(e) => (password = e.target.value)}
              label="Password"
              type="password"
            />
          </Stack>
        </form>
        <Button
          variant="contained"
          onClick={() => {
            if (email === null) setvalemail(true);
            else setvalemail(false);

            if (password === null) setvalpass(true);
            else setvalpass(false);

            if (email !== null && password !== null) {
              var data = JSON.stringify({
                email: email,
                password: password,
              });

              var config = {
                method: "post",
                url: url + "accounts/login/",
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  message = "Login Successfull!!!";
                  color = "success";
                  localStorage.setItem("type", "student");
                  localStorage.setItem("token", response.data.token);

                  setLoad(false);
                  setShow1(true);
                  navigate("/slotbook");
                })
                .catch(function (error) {
                  message = "Login Failed !!";
                  console.log(error);
                  color = "error";
                  setLoad(false);
                  setShow1(true);
                });
            }
          }}
        >
          Submit
        </Button>
        <Button
          color="error"
          sx={{ marginLeft: "5px" }}
          variant="contained"
          onClick={() => {
            setShow(true);
          }}
        >
          Register Here
        </Button>
        <br />
        {load ? <CircularProgress sx={{ m: 2 }} /> : null}
      </Container>
    </>
  );
};

export default StudentLogin;
