import PrescriptionCard from "../components/prescriptionCard";
import {Typography,Container,Grid,CircularProgress} from '@mui/material';
import {useState,useEffect} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';

let message = null, color = null

const PrescriptionHistory = ()=>{

    const [data, setData] = useState(null);
    const [doctordata, setDoctorData] = useState(null)
    const [show, setShow] = useState(false);

    useEffect(()=>{
        var config = {
            method: 'get',
            url: url + 'prescriptions/',
            headers: { 
              'Authorization': `Token ${localStorage.getItem('token')}`, 
            }
          };
          
          axios(config)
          .then(function (response) {
            console.log(response.data)
            message = "Prescriptions Loaded!!"
            color = "success"
            setData(response.data)
            setShow(true)
          })
          .catch(function (error) {
              message = "Unable to load Prescriptions !!"
              color = "error"
              setShow(true)
        });
          
    },[])



    useEffect(()=>{
     
        var config = {
            method: 'get',
            url: url + 'doctors/',
            headers: { 
            }
          };
          
          axios(config)
          .then(function (response) {
            message = "Prescriptions Loaded!!"
            color = "success"
            setDoctorData(response.data)
            
          })
          .catch(function (error) {
            message = "Unable to load Prescriptions !!"
            color = "error"
            setShow(true)
          });
          
    },[])


    return (
        <>
        <SnackBar1 show={show} module={setShow} color={color} message={message}/>
        {(data === null || doctordata === null) ?<CircularProgress sx={{m:2}}/>: (
        <Container>
        <Typography variant="h4" fontWeight={"bold"} m={2}>Past Prescriptions </Typography>
        <Grid container>
            {data.map((elem)=>{
              let doctorName = ""
              for(var el of doctordata){
                  if(el.id === elem.doctor)
                   doctorName = el.name
                }

                return (
                    <Grid item >
                    <PrescriptionCard medicinelist={elem.medicine} prescription={elem.prescription} 
                    doctor = {doctorName}
                    date = {elem.date}
                    report = {elem.report}
                    symptoms={elem.symptoms}
                    tests = {elem.tests}
                    referred={elem.referred}
                    follow_up={elem.follow_up}
                    />
                    </Grid>
                )
            })}
        
        </Grid>
        </Container>
        )}
      
        </>
    )
}

export default PrescriptionHistory;