import {Button, Modal , Box, Typography,Stack,TextField,Checkbox,FormControlLabel} from '@mui/material';
import {useState} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';
import {FormLabel} from '@mui/material';

let medicinename = null
let medicineqty = null
let date = null
let cost_per_unit = null
let issued_from = null
let threshold = null
let expiry = null
let manufacturing_date = null

let message = null, color = null;


const AddMedicineModal2 = (props)=>{

    const [valname, setvalname] = useState(false);
    const [valqty, setvalqty] = useState(false);
    const [valdate, setvaldate] = useState(false);
    const [cost, setvalcost] = useState(false);
    const [issued,setissued] = useState(false);
    const [valthresh, setvalthresh] = useState(false);
    const [valexpiry , setvalexpiry] = useState(false);
    const [valman, setvalman] = useState(false);

    const [show, setShow] = useState(false);
    

    
    const handleClose = ()=>props.module(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        display:'block',
        p: 4,       
        overflow:'scroll',
        height:'100%',
      };


    return (
        <>
        <SnackBar1 show={show} module={setShow} message={message} color={color}/>
         <Modal
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
    <Box sx={style}>
        <Typography fontWeight={"bold"} id="modal-modal-title" variant="h5" component="h2">
        Add New Medicine to Stock
        </Typography>
        <form>
      <Stack m={2} spacing={3}>
      <TextField error={valname} onChange={(e)=>medicinename = e.target.value} label="Name of Medicine" type="text"/>
      <FormLabel>Date of Issue</FormLabel>
      <TextField error={valdate} onChange={(e)=>date = e.target.value} variant="outlined" type="date"/>
      <TextField error={valqty} onChange={(e)=>medicineqty = e.target.value} label="Qty" type="number"/>
      <TextField error={cost} onChange={(e)=>cost_per_unit = e.target.value} label="Cost Per Unit" type="number"/>
      <TextField error={issued} onChange={(e)=>issued_from = e.target.value} label="Issued from" type="text"/>
      <TextField error={valthresh} onChange={(e)=>threshold = e.target.value} label="Threshold" type="number"/>
      
      {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Emergency Procurement" /> */}
      
      <FormLabel>Manufacturing Date</FormLabel>
      <TextField error={valman} onChange={(e)=>manufacturing_date = e.target.value} variant="outlined" type="date"/>

      <FormLabel>Expiry Date</FormLabel>
      <TextField error={valexpiry} onChange={(e)=>expiry = e.target.value} variant="outlined" type="date"/>

      </Stack>
    </form>
    <Button variant="contained" onClick={()=>{
        if(medicinename === null) setvalname(true)
        else setvalname(false);

        if(medicineqty === null) setvalqty(true)
        else setvalqty(false)

        if(date === null) setvaldate(true)
        else setvaldate(false)

        if(cost_per_unit === null) setvalcost(true)
        else setvalcost(false)
        
        if(issued_from === null) setissued(true)
        else setissued(false)
        
        if(threshold === null) setvalthresh(true)
        else setvalthresh(false)
        
        if(expiry === null) setvalexpiry(true)
        else setvalexpiry(false)
        
        if(manufacturing_date === null) setvalman(true)
        else setvalman(false)
        
      

        

        if (medicinename !== null && medicineqty !== null
          && date !==  null && cost_per_unit !== null && issued_from !== null && 
          threshold !== null && expiry !== null && manufacturing_date !== null
          ){
            var data = JSON.stringify({
                "name": medicinename,
                "quantity": medicineqty,
                "seller" : issued_from,
                "cost_per_piece" : cost_per_unit,
                "threshold" : threshold, 
                "expiry" : new Date(expiry).toISOString().split('T')[0],
                "manufacturing_date" : new Date(manufacturing_date).toISOString().split('T')[0],
                "date_in" : new Date(date).toISOString(),

              });
              
              var config = {
                method: 'post',
                url: url + 'medicines/',
                headers: { 
                  'Authorization': `Token ${localStorage.getItem('token')}`, 
                  'Content-Type': 'application/json', 
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                  message = "Medicine Added!!"
                  color = "success"
                // props.addMedicine((prev)=>[...prev, {
                //     name : medicinename,
                //     quantity :  medicineqty,
                // }])
                setShow(true)
                handleClose()
                window.location.reload()
              })
              .catch(function (error) {
                  message = "There was some error !!"
                  color = "error"
                  setShow(true)
                handleClose()
              });
        }
        }}>
         Add
        </Button>
        <Button variant="contained" color="error" sx={{m:2}} onClick={()=>{
            handleClose()
        }}>
         Close
        </Button>
    </Box>
    </Modal>
        </>
    )
}

export default AddMedicineModal2;