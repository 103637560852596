import {
  Button,
  Modal,
  Box,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { url } from "../constants";
import SnackBar1 from "../components/snackBar";

let name = null,
  rollnumber = null,
  age = null,
  roomnumber = null,
  email = null,
  username = null,
  password = null,
  retype = null,
  year = null,
  branch = null;
let message, color;
const StudentRegisterModal = (props) => {
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
  ];
  const [hostel, setHostel] = useState(null);
  const [valname, setvalname] = useState(false);
  const [valrollnumber, setvalrollnumber] = useState(false);
  const [valage, setvalage] = useState(false);
  const [valemail, setvalemail] = useState(false);
  const [valusername, setvalusername] = useState(false);
  const [valpassword, setvalpassword] = useState(false);
  const [valretype, setvalretype] = useState(false);
  const [valroomnumber, setvalroomnumber] = useState(false);
  const [valhostel, setvalhostel] = useState(false);
  const [valyear, setvalyear] = useState(false);
  const [valbranch, setvalbranch] = useState(false);

  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);

  const handleChange = (event) => {
    setHostel(event.target.value);
  };

  const handleClose = () => props.module(false);
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "scroll",
    p: 4,
  };
  return (
    <>
      <SnackBar1 show={show} module={setShow} message={message} color={color} />
      <Modal
        open={props.show}
        style={{
          position: "absolute",
          overflow: "scroll",
          height: "100%",
          display: "block",
          margin: "20px",
        }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} my={10}>
          <Typography
            fontWeight={"bold"}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Student Registration
          </Typography>
          <form>
            <Stack m={2} spacing={3}>
              <TextField
                error={valname}
                onChange={(e) => (name = e.target.value)}
                label="Name of Student"
                type="text"
              />
              <TextField
                error={valrollnumber}
                onChange={(e) => (rollnumber = e.target.value)}
                label="Roll Number"
                type="number"
              />
              <TextField
                error={valage}
                onChange={(e) => (age = e.target.value)}
                label="Age"
                type="number"
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Hostel</InputLabel>
                <Select
                  error={valhostel}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={hostel}
                  label="Hostel"
                  onChange={handleChange}
                >
                  {alphabets.map((elem) => {
                    return (
                      <MenuItem value={"Hostel " + elem}>
                        {"Hostel " + elem}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                error={valroomnumber}
                onChange={(e) => (roomnumber = e.target.value)}
                label="Room Number"
                type="text"
              />
              <TextField
                error={valemail}
                onChange={(e) => (email = e.target.value)}
                label="Email Address (thapar.edu)"
                type="email"
              />
              <TextField
                error={valbranch}
                onChange={(e) => (branch = e.target.value)}
                label="Branch"
                type="text"
              />
              <TextField
                error={valyear}
                onChange={(e) => (year = e.target.value)}
                label="Year"
                type="number"
              />

              <TextField
                error={valusername}
                onChange={(e) => (username = e.target.value)}
                label="Username"
                type="text"
              />
              <TextField
                error={valpassword}
                onChange={(e) => (password = e.target.value)}
                label="Password"
                type="password"
              />
              <TextField
                error={valretype}
                onChange={(e) => (retype = e.target.value)}
                label="Retype Password"
                type="password"
              />
            </Stack>
          </form>
          <Button
            variant="contained"
            onClick={(e) => {
              if (name !== null) setvalname(false);
              else setvalname(true);

              if (rollnumber !== null) setvalrollnumber(false);
              else setvalrollnumber(true);

              if (roomnumber !== null) setvalroomnumber(false);
              else setvalroomnumber(true);

              if (age !== null) setvalage(false);
              else setvalage(true);

              if (roomnumber !== null) setvalrollnumber(false);
              else setvalrollnumber(true);

              if (email !== null) setvalemail(false);
              else setvalemail(true);

              if (username !== null) setvalusername(false);
              else setvalusername(true);

              if (password !== null) setvalpassword(false);
              else setvalpassword(true);

              if (retype !== null) setvalretype(false);
              else setvalretype(true);

              if (hostel !== null) setvalhostel(false);
              else setvalhostel(true);

              if (branch !== null) setvalbranch(false);
              else setvalbranch(true);

              if (year !== null) setvalyear(false);
              else setvalyear(true);

              if (
                name !== null &&
                hostel !== null &&
                rollnumber !== null &&
                age !== null &&
                roomnumber !== null &&
                email !== null &&
                username !== null &&
                password !== null &&
                retype !== null
              ) {
                if (password !== retype) {
                  setvalpassword(true);
                  setvalretype(true);
                } else {
                  setvalretype(false);
                  setvalretype(false);
                  setLoad(true);
                  var data = JSON.stringify({
                    username: username,
                    password: password,
                    email: email,
                    is_student: true,
                    is_doctor: false,
                    is_clerk: false,
                    college_id: rollnumber,
                    name: name,
                    age: age,
                    room_num: roomnumber,
                    hostel: hostel,
                    branch: branch,
                    year: year,
                  });
                  var config = {
                    method: "post",
                    url: url + "accounts/signup/",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: data,
                  };

                  axios(config)
                    .then(function (response) {
                      handleClose(false);
                      setLoad(false);
                      message = "Successfully Registered !!";
                      color = "success";
                      localStorage.setItem("token", response.data.token);
                      localStorage.setItem("type", "student");
                      setShow(true);
                    })
                    .catch(function (error) {
                      handleClose(false);
                      setLoad(false);
                      message = "Failure !! Pls check your information!!";
                      color = "error";
                      setShow(true);
                    });
                }
              }
            }}
          >
            Submit
          </Button>

          <Button
            sx={{ m: 2 }}
            color="error"
            variant="contained"
            onClick={() => {
              props.module(false);
            }}
          >
            Close
          </Button>
          <br />
          {load ? <CircularProgress sx={{ m: 2 }} /> : null}
        </Box>
      </Modal>
    </>
  );
};

export default StudentRegisterModal;
