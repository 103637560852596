import HomeCard from '../components/homecard';
import {Grid,Container} from '@mui/material';
import Student from '../images/student.png';
import Doctor from '../images/doctor.png';
import Stock from '../images/stock.png';
import Report from '../images/report.png';
import CareTaker from '../images/caretaker.jpg';
import Faculty from '../images/faculty.png';


const cardData = [
    {
        heading : 'Student',
        description: 'A Student can book appointment with doctors and view their prescriptions in the past.',
        image : Student,
        link : '/studentlogin'
    },

    {
        heading : 'Faculty Login',
        description: 'A seperate portal for faculty login ',
        image : Faculty,
        link : '/facultylogin'
    },
    
    {
        heading : 'Doctor',
        description: 'A doctor can upload prescription for a given student and check for his/her upcoming appointments',
        image : Doctor,
        link : '/doctorlogin',
    },
    {
        heading : 'Medicine Stock',
        description: 'Manage Medicinal stock (add new medicines or remove them from the stock)',
        image : Stock,
        link:'/clerklogin'
    },
    {
        heading : 'Reports and Slots Management (Admin Section)',
        description: 'Portal to Analyse Reports in the Past',
        image : Report,
        link:'/adminlogin'
    },
    {
        heading : 'Night Paramedic Staff Portal',
        description : 'Portal to keep record of emergency Cases',
        image : CareTaker,
        link : '/caretakerlogin',
    }

]

const MainScreen = ()=>{
    return (
        <>
        <Container sx={{ justifyContent:'space-evenly'}}>
            <Grid container>
                {
                    cardData.map((elem,index)=>{
                        return (
                            <Grid item >
                            <HomeCard key={index} heading={elem.heading} 
                            description={elem.description}
                            image={elem.image}
                            link = {elem.link}
                            />
                          </Grid>
                        )
                    })
                }
      </Grid>
      </Container>
        </>
    )
}

export default MainScreen;