import {Button, Modal , Box, Typography,Stack,TextField,CircularProgress} from '@mui/material';
import {useState} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';


let name = null, collegeid = null, email = null, specialization = null, phone = null, password = null, retype = null,username = null
let color = null, message = null

const DoctorRegisterModal = (props)=>{


    const [valname, setvalname] = useState(false);
    const [valid, setvalid] = useState(false);
    const [valemail, setvalemail] = useState(false);
    const [valspez, setvalspez] = useState(false);
    const[valphone, setvalphone] = useState(false);
    const [valpass, setvalpass] = useState(false);
    const [valretype,setvalretype] = useState(false)
    const [valusername, setvalusername] = useState(false);
    const [show, setShow] = useState(false)
    const [load, setLoad] = useState(false)

    const handleClose = ()=>props.module(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        m: 5
      };
    return (
        <>
        <SnackBar1 color={color} message={message} show={show} module={setShow}/>
        <Modal
        open={props.show}
        onClose={handleClose}
        style = {{
          position:'absolute',
          overflow:'scroll',
          height:'100%',
          display:'block',
          margin:'20px'
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
    <Box sx={style}>
        <Typography fontWeight={"bold"} id="modal-modal-title" variant="h5" component="h2">
        Doctor Registration
        </Typography>
        <form>
      <Stack m={2} spacing={3}>
      <TextField error={valname}  onChange={(e)=>name = e.target.value} label="Name of Doctor" type="text"/>
      <TextField error={valid}    onChange={(e)=>collegeid = e.target.value} label="College ID" type="text"/>
      <TextField error={valemail} onChange={(e)=>email = e.target.value} label="Email (@thapar.edu)" type="email"/>
      <TextField error={valspez}  onChange={(e)=>specialization = e.target.value} label="Specialization" type="text"/>
        <TextField error={valphone} onChange={(e)=>phone = e.target.value} label="Contact Number(+91)" type="number" helperText="Eg:1234567890"/>
        <TextField error={valusername} onChange={(e)=>username = e.target.value} label="Username" type="text" />
        <TextField error={valpass} onChange={(e)=>password = e.target.value} label="Password" type="password" />
        <TextField error={valretype} onChange={(e)=>retype = e.target.value} label="Retype Password" type="password" />

       
      </Stack>
    </form>
    <Button variant="contained" onClick={()=>{
      if(name === null) setvalname(true)
      else setvalname(false)

      if(collegeid === null) setvalid(true)
      else setvalid(false)

      if(email === null) setvalemail(true)
      else setvalemail(false)

      if(specialization === null) setvalspez(true)
      else setvalspez(false)

      if(phone === null) setvalphone(true)
      else setvalphone(false)

      if(password === null) setvalpass(true)
      else setvalpass(false)

      if(retype === null) setvalretype(true)
      else setvalretype(false)

      if(username === null) setvalusername(true)
      else setvalusername(false)

      if(email !== null && specialization !== null && phone !== null && password !== null && retype !== null && username !== null){
        if(password !== retype)
        {
          setvalpass(true)
          setvalretype(true)
        }else{
          setLoad(true)
          var data = JSON.stringify({
            "username": username,
            "password": password,
            "is_student": false,
            "is_doctor": true,
            "is_clerk": false,
            "name": name,
            "mobile_num": '+91 ' + phone,
            "specialization": specialization,
            "college_id": collegeid,
            "email": email
          });
          
          var config = {
            method: 'post',
            url: url + 'accounts/signup/',
            headers: { 
              'Content-Type': 'application/json', 
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            handleClose()
            setLoad(false)
            message = "Successfully Registered !!"
            color="success"
            setShow(true)
        

          })
          .catch(function (error) {
            handleClose()
            setLoad(false)
            message = "There was some Error !!"
            color="error"
            setShow(true)
          });
        }
      }


            console.log("Here")
        }}>
          Submit
        </Button>
        <Button sx={{m:2}} color="error" variant="contained" onClick={()=>{
           props.module(false)
        }}>
          Close
        </Button>
        <br/>
        {load?<CircularProgress sx={{m:2}}/>:null}
    </Box>
    </Modal>
        </>
    )
}

export default DoctorRegisterModal; 