import {Container, Typography,Stack,Button,TextField} from '@mui/material';
import CareTakerRegistrationModal from '../components/careRegisterModal';
import {useState} from 'react';
import SnackBar1 from '../components/snackBar';
import axios from 'axios';
import {url} from '../constants';
import {useNavigate} from 'react-router-dom';


let message = null, color = null;
let email = null, pass = null

const CaretakerLogin = ()=>{


    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [valemail, setvalemail] = useState(false);
    const [valpass, setvalpass] = useState(false);
    const history = useNavigate()


    return (
        <>
        <SnackBar1 show={show1} module={setShow1} message={message} color={color}/>
        <CareTakerRegistrationModal show={show} module={setShow}/>
        <Container>
            <Typography variant="h4" m={2} fontWeight={"bold"}>Night Paramedic Staff Login</Typography>
        <form>
      <Stack m={2} spacing={3}>
        <TextField  onChange={(e)=>email = e.target.value} error={valemail} label="Email(@thapar.edu)" type="email"/>
        <TextField  onChange={(e)=>pass = e.target.value} error={valpass} label="Password" type="password" />

       
      </Stack>
    </form>
   <Button onClick={()=>{
      if(email === null) setvalemail(true);
      else setvalemail(false);

      if(pass === null) setvalpass(true)
      else setvalpass(false);

      if(email !== null && pass !== null){
        var data = JSON.stringify({
          "email": email,
          "password": pass
        });
    
      var config = {
        method: 'post',
        url: `${url}accounts/login/`,
        headers: { 
          'Content-Type': 'application/json', 
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        localStorage.setItem('type','caretaker')
        localStorage.setItem('token',response.data.token)
        history('/emergencycases')

      })
      .catch(function (error) {
        console.log(error)
        message = "Failure !!"
        color = "error"
        setShow1(true)
      });
      
        }



    }} variant="contained" >
          Submit
        </Button>
        <Button color="error" sx={{marginLeft:'5px'}} variant="contained" onClick={()=>{

            setShow(true)
            }}>
          Register Here
        </Button>
        <br/>
       
    </Container>
        </>
    )
}

export default CaretakerLogin;