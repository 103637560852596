import { Typography,TextField,Button,Container} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useState} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';
import jsPDf from 'jspdf';
import 'jspdf-autotable';



function generate(temp){
  
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDf(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Medicine Dispense Stats";
 
  const headers = [["Sno.", "Name","Age","Date","Report","Symptoms","Referred"]];
  const data = temp.map((row,index)=> [index+1, row.name, row.age ,row.date, row.report,row.symptoms,row.referred]);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  
  
  doc.text(title, marginLeft, 40);

  doc.autoTable(content);
  doc.save("report.pdf")

}


let start_date = null, end_date = null;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));




let message = null, color = null;
  

const GuestPresciptionReport = ()=>{

    const [data, setData] = useState(null)
    const [valstart, setstart] = useState(false);
    const [valend, setend] = useState(false);
    const [show, setShow] = useState(false);


    return (
        <>
        <SnackBar1 message={message} color={color} show={show} module={setShow}/>
        <Typography sx={{m:1}} variant="h4" fontWeight={"bold"}>Guest Prescription Report</Typography>
        <TextField  error={valstart} onChange={(e)=>start_date = e.target.value} type="date"  sx={{m:2}} />
            <TextField error={valend} onChange={(e)=>end_date = e.target.value} type="date"  sx={{m:2}}/>
            <Button onClick={()=>{
                if(start_date === null) setstart(true);
                else setstart(false);
                
                if(end_date === null) setend(true);
                else setend(false);

                if(start_date !== null && end_date !== null){
                        let start = new Date(start_date)
                        let end = new Date(end_date)      
          var config = {
            method: 'get',
            url: `${url}guestreport?start_date=${start.toISOString()}&end_date=${end.toISOString()}`,
            headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`

            }
          };

          axios(config)
          .then(function (response) {
            setData(response.data)
            message = "Data Loaded!!"
            color = "success"
            setShow(true)
          })
          .catch(function (error) {
            message = "There was some Error!!!"
            color = "error"
            setShow(true)
          });

                }

            }} variant="contained" sx={{m:2}} color="error">Search</Button>
            <Container>
            
            {data !== null ? (
              <>
               <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S No.</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Age</StyledTableCell>
            <StyledTableCell align="right">Date</StyledTableCell>
            <StyledTableCell align="right">Report</StyledTableCell>
            <StyledTableCell align="right">Symptoms</StyledTableCell>
            <StyledTableCell align="right">Referred</StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,index) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {index + 1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.age}</StyledTableCell>
              <StyledTableCell align="right">{row.date}</StyledTableCell>
              <StyledTableCell align="right">{row.report}</StyledTableCell>
              <StyledTableCell align="right">{row.symptoms}</StyledTableCell>
              <StyledTableCell align="right">{row.referred}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              </>
            ) : null }
           
    </Container>

    <Button onClick={()=>{
      generate(data)
    }} sx={{m:2}} variant="contained" color="error">Print</Button>

        </>
    )
}

export default GuestPresciptionReport;