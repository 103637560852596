import {Button, Modal , Box, Typography} from '@mui/material';



const EmergencyModal = (props)=>{
    
    const handleClose = ()=>props.module(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };


    return (
        <>

         <Modal
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
    <Box sx={style}>
        <Typography fontWeight={"bold"} my={2} id="modal-modal-title" variant="h5" component="h2">
        Declaring Emergency !! Are you sure?
        </Typography>

    <Button variant="contained" color="success" onClick={()=>{
          props.module(false)
        }}>
         Confirm
        </Button>
        <Button variant="contained" color="error" sx={{m:2}} onClick={()=>{
            props.module(false)
        }}>
         Close
        </Button>
    </Box>
    </Modal>
        </>
    )
}

export default EmergencyModal;