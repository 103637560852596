import {Modal,Box, Typography,Button} from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  
const ColorCodingInfo =  (props)=>{

    const handleClose = ()=> props.module(false);
    return (
        <>
        <Modal
  open={props.show}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     Color Coding : 
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <ul style={{listStyle:'None'}}>
            <li style={{marginBottom:'10px'}}><Button variant="contained" color="success">25</Button> More than 20 slots left</li>
            <li style={{marginBottom:'10px'}}><Button variant="contained" color="warning">10</Button> Less than 10 slots left</li>
            <li style={{marginBottom:'10px'}}><Button variant="contained" color="error">0</Button> No Slots left</li>

        </ul>
    </Typography>
  </Box>
</Modal>

        </>
    )
}
export default ColorCodingInfo;