import './App.css';
import {Routes, Route} from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/footer';

import MainScreen from './pages/MainScreen';
import MedicineStock from './pages/MedicineStock';
import StudentLogin from './pages/StudentLogin';
import FacultyLogin from './pages/facultyLogin';
import ClerkLogin from './pages/ClerkLogin';
import DoctorLogin from './pages/DoctorLogin';
import SlotBooking from './pages/SlotBooking';
import PrescriptionHistory from './pages/PrescriptionHistory';
import AppointmentHistory from './pages/AppointmentHistory';
import PrescriptionByStudentId from './pages/prescriptionbystudentId';
import AddPrescription from './pages/addPrescription';
import SearchForMedicines from './pages/searchforMedicines';
import SearchStudentbySlot from './pages/searchStudentbySlot';
import DispenseMedicine from './pages/DispenseMedicine';
import AdminLogin from './pages/adminLogin';


import StudentVisit from './pages/studentsVisit';
import ProblemDiagnosed from './pages/problemsDiagnosed';
import StudentVisitDoctor from './pages/studentVisitDoctor';
import AddEmergencyStudent from './pages/addEmergencyStudent';
import CareTakerLogin from './pages/caretakerlogin';
import ViewEmergencyCases from './pages/ViewEmergencyCases';
import MedicineInventoryStatus from './pages/medicineInventoryStatus';
import EmergencyMedicineReport from './pages/emegegencyMedicineReport';
import AdminPortal from './pages/adminPortal';
import AddGuestPrescription from './pages/addGuestPrescription';
import GuestPresciptionReport from './pages/guestPrescriptionReport';
import IndivdualReport from './pages/IndividualReport';

function App() {
  return (
    <>
    <NavBar/>
    <div className="App" style={{"marginBottom":'30px'}}>
      <Routes>
        <Route exact path="/" element={<MainScreen/>}/>
        <Route exact path="/studentlogin" element={<StudentLogin/>}/>
        <Route exact path="/facultylogin" element={<FacultyLogin/>}/>
        <Route exact path="/doctorlogin" element={<DoctorLogin/>}/>
        <Route exact path="/clerklogin" element={<ClerkLogin/>}/>
        <Route exact path="/slotbook" element={<SlotBooking/>}/>
        <Route exact path="/appointmenthistory" element={<AppointmentHistory/>}/> 
        <Route exact path="/prescriptionhistory" element={<PrescriptionHistory/>}/>
        <Route exact path="/prescriptionsearch" element={<PrescriptionByStudentId/>} /> 
        <Route exact path="/addprescription" element={<AddPrescription/>}/>
        <Route exact path="/medicinestock" element={<MedicineStock/>}/>
        <Route exact path="/searchstudent" element={<SearchStudentbySlot/>} />

        <Route exact path="/searchformedicines" element={<SearchForMedicines/>} />
        <Route exact path="/problemdiagnosed" element={<ProblemDiagnosed/>}/>
        <Route exact path="/studentvisit" element={<StudentVisit/>} />
        <Route exact path="/studentvisitdoctor" element={<StudentVisitDoctor/>}/>
        
        <Route exact path="/dispensemedicine" element={<DispenseMedicine/>} />
        <Route exact path="/emergencycases" element={<AddEmergencyStudent/>}/>
        <Route exact path="/viewemergencycases" element={<ViewEmergencyCases/>}/>
        <Route exact path="/addguestprescription" element={<AddGuestPrescription/>}/>
        
        
        <Route exact path="/caretakerlogin" element={<CareTakerLogin/>} />
        <Route exact path="/medicineinventory" element={<MedicineInventoryStatus/>} />
        <Route exact path="/emergencymedicinereport" element={<EmergencyMedicineReport/>} />
        <Route exact path="/adminportal" element={<AdminPortal/>}/>
        <Route exacth path="/adminlogin" element={<AdminLogin/>}/>
        <Route exact path="/guestreport" element={<GuestPresciptionReport/>}/>
        <Route exact path="/individualreport" element={<IndivdualReport/>} />
        </Routes>
    </div>
    <Footer/>
    </>
  );
}

export default App;
