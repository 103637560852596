import { AppBar,Toolbar ,Typography,IconButton,Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuDrawer from '../components/drawer';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import EmergencyModal from '../components/EmergencyModal';
import NotificationMenu from '../components/NotificationMenu';

let navbarheading=""
const NavBar = ()=>{

    const [show,setShow] = useState(false);
    const [show1, setShow1] = useState(false);
   
    const navigate = useNavigate()


    if(localStorage.getItem('type') === null)
    navbarheading = "Health Care Management System";

    else if(localStorage.getItem('type') === 'student')
    navbarheading = "Welcome Student"

    else if(localStorage.getItem('type') === 'doctor')
    navbarheading = "Welcome Doctor";


    else if(localStorage.getItem('type') === 'faculty')
    navbarheading = "Welcome Faculty";

    else if(localStorage.getItem('type') === 'clerk')
    navbarheading = "Welcome Clerk"

    else if(localStorage.getItem('type') === 'any')
    navbarheading = 'Medical Report Section'

    else if(localStorage.getItem('type') === 'caretaker')
    navbarheading = 'Welcome Night Paramedic'



  

    return (
        <>
        <EmergencyModal show={show1} module={setShow1}/>
        <MenuDrawer show={show} module={setShow}/>
         <AppBar position="sticky" style={{backgroundColor:'#FF2D0D'}}>
          
        <Toolbar>
       
        {localStorage.getItem('type') !== null ?(
        <>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={()=>{
                setShow(true)
            }}
            sx={{ mr: 2 }}
          > <MenuIcon/></IconButton>
          </>
          
          ): null}

            <Typography variant="h5" style={{fontWeight:"bold",flexGrow:1}}>{navbarheading}</Typography>
            {/* {localStorage.getItem('type') === "doctor" || localStorage.getItem('type') === "student"?(<Button onClick={()=>setShow1(true)} variant="contained" color="error" sx={{mx:2}}>Emergency</Button>):null} */}
            {localStorage.getItem('type') === 'clerk'?(
               <NotificationMenu/>
            ): null}


            
            {localStorage.getItem('type') !== null?(
               <Button variant="contained" onClick={()=>{
                localStorage.removeItem('type')
                localStorage.removeItem('token')
                navigate("/")
              }} color="error">Logout</Button>
            ): null}


           
        </Toolbar>
      </AppBar>
        </>
    )
}

export default NavBar;