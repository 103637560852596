import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell ,{ tableCellClasses }from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import AddMedicineModal2 from '../components/addMedicineModal2';
import { TextField } from '@mui/material';
import SnackBar1 from '../components/snackBar';
import {Typography,CircularProgress,
Grid} from '@mui/material';

import {useState,useEffect} from 'react';
import {url} from '../constants';
import axios from 'axios';
import jsPDf from 'jspdf';
import 'jspdf-autotable';

let searchdate = null

function generate(temp){
  
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDf(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Emergency Medicine Report";
 
  const headers = [["Sno.", "Name of the Medicine","Qty"]];
  const data = temp.map((row,index)=> [index+1, row.name,row.quantity]);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  
  
  doc.text(title, marginLeft, 40);

  doc.autoTable(content);
  doc.save("report.pdf")

}



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
   
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

let message = null, color = null;
let date = null;

const MedicineStock = ()=>{

  const [show, setShow] = useState(false);
  const [showsnack, setShowsnack] = useState(false);
  const [load, setLoad] = useState(false);
  const [rows, setRows] = useState([
  
  ])
  const [emergencyRows, setEmergencyRows] = useState([])
  const [valdate, setValDate] = useState(false);



  useEffect(()=>{


    var config = {
      method: 'get',
      url: url + 'medicines/',
      headers: { 
        'Authorization': `Token ${localStorage.getItem('token')}`, 
      }
    };
    
    axios(config)
    .then(function (response) {
      setLoad(false);
      setRows(response.data)
      message = "Inventory Loaded"
      color = "success"
      setShowsnack(true)
    })
    .catch(function (error) {
      setLoad(false);
      message = "There was some Error"
      color = "error"
      setShowsnack(true)
    });
  },[])

    return (
        <>
        <SnackBar1 show={showsnack} module={setShowsnack} message={message} color={color}/>
        <AddMedicineModal2 show={show}  module={setShow} addMedicine={setRows}/>
        <Typography variant="h4" m={2} fontWeight={"bold"}>Medicine Stock</Typography>
        
        <Grid id="top-row" container spacing={8}>
        <Grid item xs={6}> 
        <Typography variant="h5" sx={{m:2,fontWeight:"bold"}}>Table 1: Generic Inventory</Typography>

        {load?<CircularProgress/>:(
 <Container>
 <TableContainer component={Paper}>
<Table sx={{ minWidth: 700 }} aria-label="customized table">
 <TableHead>
   <TableRow>
     <StyledTableCell >S No.</StyledTableCell>
     <StyledTableCell align="right" sx={{fontSize:'13px'}}>Name of Medicine</StyledTableCell>
     <StyledTableCell align="right" sx={{fontSize:'13px'}}>Qty.(per pack)</StyledTableCell>
     <StyledTableCell align="right" sx={{fontSize:'13px'}}>Add to Emergency</StyledTableCell>
     
     {/* <StyledTableCell align="right" sx={{fontSize:'19px'}}>Save</StyledTableCell> */}

   </TableRow>
 </TableHead>
 <TableBody>
   {rows.map((row,index) => (
     <StyledTableRow key={index}>
       <StyledTableCell component="th" scope="row">
         {index + 1}
       </StyledTableCell>
       <StyledTableCell align="right">{row.name}</StyledTableCell>
       <StyledTableCell align="right"><TextField disabled type="number" onChange={(e)=>row.quantity = e.target.value} defaultValue={row.quantity} label="Qty." variant="outlined" /></StyledTableCell>
       <StyledTableCell align="right">
        <Button onClick={()=>{
 
            var data = JSON.stringify({
              "date": new Date().toISOString().split('T')[0],
              "name": row.name,
              "quantity": row.quantity
            });
            
            var config = {
              method: 'post',
              url: `${url}medicines/emergency/`,
              headers: { 
                'Authorization': `Token ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
              },
              data : data
            };

                    axios(config)
        .then(function (response) {
          message = "Successfully Added"
          color = "success"
          setShowsnack(true)
        })
        .catch(function (error) {
          message = "There was some error"
          color = "error"
          setShowsnack(true)
          
        });

        }} variant="contained" color="error">Add</Button>
       </StyledTableCell>
     </StyledTableRow>
   ))}
 </TableBody>
</Table>
</TableContainer>
<Button sx={{float : "left" ,mb: 2,mt:2}} variant="contained" color="success" onClick={()=>{
setShow(true)
}}>
   Add New Medicine
 </Button>
</Container>

        )}
         </Grid>
         <Grid item xs={6} > 
         <Container>
         <Typography variant="h5" sx={{m:2,fontWeight:"bold"}}>Table 2:Emergency Medicines</Typography>
         <TextField error={valdate} type="date" onChange={(e)=>date = e.target.value} variant="outlined" sx={{m:2}}/> <Button onClick={()=>{
            if(date === null) setValDate(true)
            else setValDate(false);

            if(date !== null){

              let check_date = new Date(date)
              var config = {
                method: 'get',
                url: `${url}medicines/emergency/?date=${check_date.toISOString().split('T')[0]}`,
                headers: { 
                  'Authorization': `Token ${localStorage.getItem('token')}`
                }
              };
              
              axios(config)
              .then(function (response) {
                  setEmergencyRows(response.data)
                  color = "success"
                  message = "Successfully Loaded!!"
                  setShowsnack(true)
              })
              .catch(function (error) {
                console.log(error);
                color = "error"
                message = "There was some Error!!"
                setShowsnack(true)
              });
              
            }

         }} variant="contained" color="error" sx={{m:2}}>Submit</Button>

 <TableContainer component={Paper}>
<Table sx={{ minWidth: 700 }} aria-label="customized table">
 <TableHead>
   <TableRow>
     <StyledTableCell >S No.</StyledTableCell>
     <StyledTableCell align="right" sx={{fontSize:'13px'}}>Name of Medicine</StyledTableCell>
     <StyledTableCell align="right" sx={{fontSize:'13px'}}>Qty.(per pack)</StyledTableCell>
     

   </TableRow>
 </TableHead>
 <TableBody>
   {emergencyRows.map((row,index) => (
     <StyledTableRow key={index}>
       <StyledTableCell component="th" scope="row">
         {index + 1}
       </StyledTableCell>
       <StyledTableCell align="right">{row.name}</StyledTableCell>
       <StyledTableCell align="right"><TextField disabled type="number" onChange={(e)=>row.quantity = e.target.value} defaultValue={row.qty} label="Qty." variant="outlined" /></StyledTableCell>
     </StyledTableRow>
   ))}
 </TableBody>
</Table>
</TableContainer>

<Button onClick={()=>{
  generate(emergencyRows)
}} sx={{float : "left" ,mb: 2,mt:2}}  variant="contained" color="error">Print Report</Button>
</Container>
         </Grid>

       </Grid>



        </>
    )
}

export default MedicineStock;