import {Typography,TextField,Button,Container, CircularProgress} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import TableCell ,{ tableCellClasses }from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useState} from 'react';
import SnackBar1 from '../components/snackBar';
import {url} from '../constants';
import axios from 'axios';


let startdate = null, enddate = null;

let message = null, color = null;



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 17,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
   
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Prominent Diseases in Health Care System',
      },
    },

  };


const labels = ['Fever', 'Cold', 'Headache', 'Malaria'];
let data1 = null

const ProblemDiagnosed = ()=>{
 
      const [show1, setShow1] = useState(false);
      const [data, setData]  = useState(null);
      const [load, setLoad] = useState(false);
      const [valsdate, setvalsdate] = useState(false);
      const [valedate, setvaledate] = useState(false);

    return (
        <>
        <SnackBar1 show={show1} module={setShow1} message={message} color={color}/>
        <Typography variant="h4" fontWeight={"bold"} m={2}>Problem Diagnosis report</Typography>
        <TextField onChange={(e)=>startdate = e.target.value }  error={valsdate} type="date" sx={{m:2}} label="Start Date" InputLabelProps={{ shrink: true }}/>
        <TextField onChange={(e)=>enddate = e.target.value }    error={valedate} type="date" sx={{m:2}} label="End Date" InputLabelProps={{ shrink: true }}/>
        <Button variant="contained" color="error" sx={{m:2}} onClick={()=>{
          if(startdate === null) setvalsdate(true);
          else setvalsdate(false)

          if(enddate === null) setvaledate(true);
          else setvaledate(false)
            
          if(startdate !== null && enddate !== null){
            var config = {
              method: 'get',
              url: url + `report/?start_date=${new Date(startdate).toISOString()}&end_date=${new Date(enddate).toISOString()}`,
              headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
          
            };
            
            axios(config)
            .then(function (response) {
              message = "Success"
              color = "success"

              setData(response.data)
              data1 = {
                labels : Object.keys(data.symptoms_diagnosed).filter((elem)=>elem !==""),
                datasets: [
                  {
                    label: 'Cases',
                    data: Object.keys(data.symptoms_diagnosed).filter((elem)=>elem !=="").map((e)=>(data.symptoms_diagnosed[e])),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  },
                 
                ],
              };
              setLoad(false)
              setShow1(true)
            
            })
            
             
            .catch(function (error) {
              message = "Error!!"
              color = "error"
              setLoad(false);
              setShow1(true)
            });
          }
           
            
            
            
        }}>Search</Button>
        {data !== null && data1 !== null?(
          <>
          <Container>
 <Bar options={options} data={data1} />
 </Container>
 <Container sx={{my:2}}>
               <TableContainer component={Paper}>
             <Table sx={{ minWidth: 700 }} aria-label="customized table">
               <TableHead>
                 <TableRow>
                   <StyledTableCell >S No.</StyledTableCell>
                   <StyledTableCell align="right" sx={{fontSize:'19px'}}>Problem Diagnosed</StyledTableCell>
                   <StyledTableCell align="right" sx={{fontSize:'19px'}}>Total Cases</StyledTableCell>
          
                 </TableRow>
               </TableHead>
               <TableBody>
                 {Object.keys(data.symptoms_diagnosed).filter((e)=>e !== "").map((row,index) => (
                   <StyledTableRow key={index}>
                     <StyledTableCell component="th" scope="row">
                       {index + 1}
                     </StyledTableCell>
                     <StyledTableCell align="right" sx={{fontSize:'15px'}}>{row}</StyledTableCell>
                     <StyledTableCell align="right" sx={{fontSize:'15px'}}>{data.symptoms_diagnosed[row]}</StyledTableCell>
                   </StyledTableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
           </Container>
          </>
        ):null}
  <br/>
        {load?<CircularProgress/>:null}
        
        </>
    )
}

export default ProblemDiagnosed;