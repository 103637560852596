import { Typography,Container} from "@mui/material";
import {TextField,Button} from '@mui/material';
import {useState} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';


let id = null;
let color, message;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];


const IndivdualReport = ()=>{

    const [valid, setvalid] = useState(false);
    const [data, setData] = useState(null)
    const [show, setShow] = useState(false);



    return (
        <>
        <SnackBar1 show={show} message={message} color={color}/>
        <Typography variant="h4" fontWeight="bold" sx={{m:2}}>Indivdual Student Report</Typography>
        <TextField 
        error={valid}
        onChange={(e)=>id = e.target.value}
        id="outlined-basic" label="ID: "  variant="outlined" />
        <Button onClick={(e)=>{
            if(id === null) setvalid(true);
            else setvalid(false);

            if(id !== null){
                var config = {
                    method: 'get',
                    url: `${url}report/individual/?college_id=${id}`,
                    headers: { 
                      'Authorization': `Token  ${localStorage.getItem('token')}`, 
                    }
                  };
                  
                  axios(config)
                  .then(function (response) {
                    setData(response.data)
                    message = "Data Loaded"
                    color = "success"
                    setShow(true)
                })
                  .catch(function (error) {
                    
                    message = "Search ID not found!!"
                    color = "error"
                    setShow(true)
                  });
            }

        }} sx={{m:2}} variant="contained" color="error">Submit</Button>


        {data !== null?(
            <>
              <Container>
              <Typography sx={{m:2}} variant="h5" fontWeight={"bold"}>Table 1 : Details of the Person</Typography>
  <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Details</StyledTableCell>
              <StyledTableCell align="right">Value</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                      College ID
                  </StyledTableCell>
                  <StyledTableCell align="right">{data.info.college_id}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                  Name
                      </StyledTableCell>
                  <StyledTableCell align="right">{data.info.name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                      Age
                  </StyledTableCell>
                  <StyledTableCell align="right">{data.info.age}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                      Branch (If Any)
                  </StyledTableCell>
                  <StyledTableCell align="right">{data.info.branch}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                  Total Visits
                  </StyledTableCell>
                  <StyledTableCell align="right">{data.info.total_visits}</StyledTableCell>
                </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </Container>
  
      <Container >
          <Typography variant="h5" fontWeight={"bold"} sx={{m:2}}>Table 2 : Student Prescription Details</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
            <StyledTableCell>S No.</StyledTableCell>
              <StyledTableCell>Date of Prescription</StyledTableCell>
              <StyledTableCell align="right">Report</StyledTableCell>
              <StyledTableCell align="right">Symptoms</StyledTableCell>
              <StyledTableCell align="right">Symptoms</StyledTableCell>
              <StyledTableCell align="right">Referred</StyledTableCell>
              <StyledTableCell align="right">Follow Up</StyledTableCell>
              <StyledTableCell align="right">Emergency</StyledTableCell>
  
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data.records).map((row,index) => (
              <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row}
                </StyledTableCell>
                <StyledTableCell align="right">{data.records[row].report}</StyledTableCell>
                <StyledTableCell align="right">{data.records[row].symptoms}</StyledTableCell>
                <StyledTableCell align="right">{data.records[row].tests}</StyledTableCell>
                <StyledTableCell align="right">{data.records[row].referred}</StyledTableCell>
                <StyledTableCell align="right">{data.records[row].follow_up?"Yes" : "No"}</StyledTableCell>
                <StyledTableCell align="right">{data.records[row].emergency?"Yes": "No"}</StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Container>
      </>
        ) : null}
      
        </>
    )
}
export default IndivdualReport;