import {Box, Modal,Typography,Button} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell ,{ tableCellClasses }from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
   
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  


const PrescriptionDetailsModal = (props)=>{

const handleClose =()=>props.module(false);
  return (
    <Modal
    open={props.show}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
        Prescription Details: - 
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <strong>Doctor:</strong> {props.doctor}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <strong>Symptoms:</strong> {props.symptoms}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <strong>Prescription:</strong> {props.prescription}
      </Typography>

      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <strong>Follow Up: </strong> {props.follow_up?"YES":"NO"}
      </Typography>

      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <strong>Date:</strong> {new Date(props.date).toDateString()}
      </Typography>


      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <strong>Referral:</strong> {props.referral}
      </Typography>


      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <strong>Medicines Prescribed:</strong>
      </Typography>


      <TableContainer component={Paper} sx={{m:2}}>
      <Table sx={{ minWidth: 600 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell >S No.</StyledTableCell>
            <StyledTableCell align="right" sx={{fontSize:'15px'}}>Name of Medicine</StyledTableCell>
            <StyledTableCell align="right" sx={{fontSize:'15px'}}>Time of the Day</StyledTableCell>
            <StyledTableCell align="right" sx={{fontSize:'15px'}}>Days</StyledTableCell>
            <StyledTableCell align="right" sx={{fontSize:'15px'}}>Remarks</StyledTableCell>

   
          </TableRow>
        </TableHead>
        <TableBody>
          {props.medicine !== undefined?(
            <>
            {
            props.medicine.map((elem,index)=>{
              return (
                <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{fontSize:'15px'}}>{elem.name}</StyledTableCell>
                <StyledTableCell align="right" sx={{fontSize:'15px'}}>{elem.choice}</StyledTableCell>
                <StyledTableCell align="right" sx={{fontSize:'15px'}}>{elem.days}</StyledTableCell>
                <StyledTableCell align="right" sx={{fontSize:'15px'}}>{elem.remarks}</StyledTableCell>
          
              </StyledTableRow>
              )
            })
          }
            </>
          ):null

          }
          
        </TableBody>
      </Table>
    </TableContainer>



  <Button onClick={handleClose} variant="contained" color="error">Close</Button>
    </Box>
  </Modal>
  )
}


export default PrescriptionDetailsModal;