import {TextField,Container,Button,Box, Typography, Modal} from '@mui/material';
import {useState} from 'react';
import axios from 'axios';
import Snackbar1 from '../components/snackBar';
import {url} from '../constants';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  m: 5
};

let name = null,college_id = null, spez = null, mobile = null, username = null, email = null, pass = null, repass = null;

let message = null, color = null;
const CareTakerRegistrationModal = (props)=>{

    const handleClose = ()=>props.module(false);
    const [valname, setvalname] = useState(false);
    const [valid, setvalid] = useState(false);
    const [valpass1, setvalpass1] = useState(false);
    const [valspez, setvalspez] = useState(false);
    const [valpass2, setvalpass2] = useState(false);
    const [valmobile, setvalmobile] = useState(false);
    const [valuser, setvaluser] = useState(false);
    const [valemail, setvalemail] = useState(false);
    const [show, setShow] = useState(false);
    



    return (
        <>
        <Snackbar1 show={show} message={message} color={color} module={setShow}/>
         <Modal
            style = {{
              position:'absolute',
              overflow:'scroll',
              height:'100%',
              display:'block',
              margin:'20px'
            }}
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" sx={{my:5}} variant="h6" component="h2">
          Night Paramedic Registration 
            </Typography>
          <Container sx={{mx: 2,my: 5}}>
            <TextField
          required
          id="outlined-required"
          label="Name"
          type="text"
          sx={{my: 2}}
          error={valname}
          onChange={(e)=>{
            name = e.target.value
          }}
        />
        <TextField
          required
          id="outlined-required"
          label="College ID"
          type="number"
          error={valid}

          onChange={(e)=>college_id = e.target.value}
          sx={{my: 2}}
        />
        <TextField
          required
          id="outlined-required"
          label="Specialization"
          type="text"
          error={valspez}

          onChange={(e)=>spez = e.target.value}
          sx={{my: 2}}
        />
        <TextField
          required
          id="outlined-required"
          label="Mobile Number (+91): "
          type="number"
          onChange={(e)=>mobile = e.target.value}
          error={valmobile}
          sx={{my: 2}}
        />
          <TextField
          required
          id="outlined-required"
          label="username"
          type="text"
          onChange={(e)=>username = e.target.value}
          error={valuser}
          sx={{my: 2}}
        />
          <TextField
          required
          id="outlined-required"
          label="email"
          type="email"
          onChange={(e)=>email = e.target.value}
          error={valemail}

          sx={{my: 2}}
        />
        <TextField
          required
          id="outlined-required"
          label="password"
          type="password"
          onChange={(e)=>pass = e.target.value}
          error={valpass1}
          sx={{my: 2}}
        />
        <TextField
          required
          id="outlined-required"
          label="retype-password"
          type="password"
          onChange={(e)=>repass = e.target.value}
          error={valpass2}
          sx={{my: 2}}
        />
        <Button variant="contained" color="error" onClick={()=>{
          if(name === null) setvalname(true)
          else setvalname(false);
          

          if(college_id === null) setvalid(true)
          else setvalid(false)

          if(spez === null) setvalspez(true)
          else setvalspez(false)

          if(mobile === null) setvalmobile(true)
          else setvalmobile(false)

          
          if(username === null) setvaluser(true)
          else setvaluser(false)

          if(email === null) setvalemail(true)
          else setvalemail(false)
          
          if(pass === null) setvalpass1(true)
          else setvalpass1(false)

          if(repass === null) setvalpass2(true)
          else setvalpass2(false)

          if(pass !== repass && (pass !== null || repass !== null)){
            setvalpass1(true)
            setvalpass2(true)
          }
          else {
            setvalpass1(false)
            setvalpass2(false)
          }

          if(name !== null && college_id !== null && spez !== null && mobile !== null && 
            username !== null && email !== null && pass !== null && repass !== null && pass === repass){

              var data = JSON.stringify({
                "college_id": college_id,
                "name": name,
                "specialization": spez,
                "mobile_num": `+91 ${mobile}`,
                "password": pass,
                "username": username,
                "email": email,
                "is_paramedic": true,
                "is_student": false,
                "is_doctor": false,
                "is_clerk": false
              });
              
              var config = {
                method: 'post',
                url: `${url}accounts/signup/`,
                headers: { 
                  'Content-Type': 'application/json', 
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                message = "Successfully Registered !!!"
                color = "success"
                setShow(true)
              })
              .catch(function (error) {
                  message = "There was some Error!!"
                  color = "error"
                  setShow(true)
              });
              
              handleClose()


            }





        }}>Submit</Button> 
        <Button onClick={handleClose} sx={{m:2}} variant="contained">Close</Button>

        </Container>

        </Box>
      </Modal>
        </>
    )
}

export default CareTakerRegistrationModal;