import {Typography,TextField,Button,CircularProgress} from '@mui/material';
import {useState} from 'react';
import StudentinfoListItem from '../components/StudentinfoListItem';
import SnackBar1 from '../components/snackBar';
import axios from 'axios';
import {url,slot_keys} from '../constants';


let d = null;
let message = null, color = null;



const SearchStudentbySlot = ()=>{
    const [data, setData] = useState(null)
    const [showsnack,setSnack] = useState(false)
    const [valdate, setvaldate] = useState(false)
    const [load, setLoad] = useState(false)
    return (
        <>
        <SnackBar1 show={showsnack} module={setSnack} message={message} color={color}/>
        <Typography variant="h4" my={2} fontWeight={"bold"}>
        Search for your Slots
        </Typography>
       

        <TextField error={valdate} onChange={(e)=>d = e.target.value} type="date" sx={{m:2}} label="Start Date" InputLabelProps={{ shrink: true }}/>

      
        <Button color="success" variant="contained" style={{"marginTop":"20px"}} onClick={()=>{
         
         if(d === null) setvaldate(true)
         else setvaldate(false)

         if(d !== null){
           setLoad(true)
          var config = {
          
            method: 'get',
            url: url + 'doctor/slots1/',
            headers: { 
              'Authorization': `Token ${localStorage.getItem('token')}`, 
              'Content-Type': 'application/json', 
            },
            params : {
              date : new Date(Date.parse(d)).toISOString()
            }
          };
          
          axios(config)
          .then(function (response) {
            message = "Slots Loaded !!"
            color = "success"
            setSnack(true)
           setData(response.data)
           console.log(response.data)
            setLoad(false)
          })
          .catch(function (error) {
            message = "There was some error!!"
            color = "error"
          setSnack(true)
          setLoad(false)
          });
           
         }
        
       
        
        }}>Search</Button>
        <br/>
    {load?<CircularProgress/>:null}
        {load === false && data !== null?(
          <>
          {data.map((elem)=>{
            return (<StudentinfoListItem 
            roll={elem.id}
            slot={slot_keys[elem.slot]}
            time={new Date(elem.date).toLocaleDateString()}
            student={elem.student}
            />)
          })}
           
          </>

        ):null}
    
        
        </>
    )
}

export default SearchStudentbySlot;