import {Card, CardContent, Typography,Button} from '@mui/material';
import {useState} from 'react';
import PrescriptionDetailsModal from '../components/prescriptionDetailsModal';


const PrescriptionCard = (props)=>{

  const [show,setShow] = useState(false);


    return (
        <Card sx={{ width : 480,m:2 }} style={{backgroundColor: "#C9653E"}}>
          <PrescriptionDetailsModal show={show} module={setShow}
          prescription={props.prescription}
          medicine={props.medicinelist}
          doctor={props.doctor}
          date={props.date}
          report={props.report}
          test={props.tests}
          symptoms={props.symptoms}
          referral={props.referred}
          follow_up = {props.follow_up}
          />
        <CardContent >
          <Typography gutterBottom variant="h6" component="div" fontWeight={"bold"} color={"white"}>
            With {props.doctor}
          </Typography>
          <Typography gutterBottom variant="body1" component="div" fontWeight={"bold"} color={"white"}>
            Date : {new Date(props.date).toDateString()}
          </Typography>
          <Typography variant="body1" color={"white"}>
            Prescription: 
          </Typography>
          <Typography variant="body1" color={"white"}>
            {props.prescription}
          </Typography>
          <Button onClick={()=>{
            setShow(true)
          }} sx={{m:2}} variant="contained" color="success">View Details</Button>
        </CardContent>
      </Card>
    )
}


export default PrescriptionCard;


