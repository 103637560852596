import {AppBar,Typography,Container,Toolbar} from '@mui/material'
let date = new Date()


const Footer = ()=>{
    return (
        <div className="footer">
        <AppBar position="static" style={{backgroundColor:'#FF2D0D'}} >
        <Container maxWidth="md" >
          <Toolbar sx={{
              justifyContent:'space-evenly'
          }}>
            <Typography sx={{fontWeight:'bold'}} variant="h6" color="inherit">
              © {date.getFullYear()}-{date.getFullYear() +1} All rights reserved
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      </div>
    )
}


export default Footer;