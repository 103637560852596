import {TextField,Button,Container,Typography,Grid,CircularProgress} from '@mui/material';
import PrescriptionCard from "../components/prescriptionCard";
import {useState} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';

let studentid = null
let message = null, color = null


const PrescriptionByStudentId = ()=>{

    const [show, setShow] = useState(false);
    const [valid, setvalid] = useState(false);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState(null);
    

    return (
        <>
        <SnackBar1 show={show} module={setShow} color={color} message={message}/>
        <Typography variant="h4" sx={{m:2}} fontWeight={"bold"}>Search Past prescriptions of Student by their ID: - </Typography>
        <TextField error={valid} onChange={(e)=>studentid = e.target.value} id="outlined-basic" label="Student Id" type="text" variant="outlined" />
        <Button onClick={()=>{
            if(studentid !== null) setvalid(false)
            else setvalid(true)

            if(studentid !== null){
                setLoad(true)
                var config = {
                    method: 'get',
                    url: url + `prescriptions/get/?college_id=${studentid}`,
                    headers: { 
                      'Authorization': `Token ${localStorage.getItem('token')}`, 
                    }
                  };
                  
                  axios(config)
                  .then(function (response) {
                    message = "Prescriptions Loaded!!"
                    color = "success"
                    setShow(true)
                    setLoad(false)
                    setData(response.data)
                  })
                  .catch(function (error) {
                      message = "Student ID not found!!"
                      color = "error"

                    setShow(true)
                    setLoad(false)
                  });
            }

        }} variant="contained" color="success" sx={{m:2}}>Search</Button>
        {data !== null?(
              <Container>
              <Typography variant="h4" fontWeight={"bold"} m={2}>Past Prescriptions </Typography>
              <Grid container>
                  {data.map((elem,index)=>{
                 
                      return (
                        <Grid item >
                        <PrescriptionCard
                         medicinelist={elem.medicine} 
                         prescription={elem.prescription} 
                         doctor = {elem.doctor}
                         date = {elem.date}
                         report = {elem.report}
                         symptoms={elem.symptoms}
                         referred={elem.referred}
                         follow_up={elem.follow_up}
                         tests = {elem.tests}
                        />
                        </Grid>
                      )
                  })}
                 

                  
              </Grid>
              </Container>
        ):null}
        <br/>
        {load?<CircularProgress/> : null}
      
        </>
    )
}


export default PrescriptionByStudentId;