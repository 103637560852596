import {Snackbar,Alert,LinearProgress} from '@mui/material';

const SnackBar1 = (props)=>{

    const handleClose = ()=>props.module(false)
    return (
      <>
      {props.show?(<LinearProgress  autoHideDuration={2000} color="error" />) : null}
        <Snackbar open={props.show} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={2000} onClose={props.module}>
        <Alert onClose={handleClose} severity={props.color} sx={{ width: '100%' }}>
          {props.message}
        </Alert>
      </Snackbar>
      </>
    )

}

export default SnackBar1;