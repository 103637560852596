import {Card,Typography,CardContent,Button,CardActions} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StudentinfoListItem = (props)=>{

  const navigate = useNavigate();
    return (
        <>
         <Card sx={{ maxWidth: 1000 ,backgroundColor:"#C9653E",mx:'auto',my:5}}>
      <CardContent>
        <Typography gutterBottom variant="h6" align='left' component="div" color={"white"} fontWeight="bold">
          Colleege ID : {props.roll}
        </Typography>
        <Typography gutterBottom variant="h6" align='left' component="div" color={"white"} fontWeight="bold">
          Slot  : {props.slot}
        </Typography>
        <Typography gutterBottom variant="h6" align='left' component="div" color={"white"} fontWeight="bold">
          Time  : {props.time}
        </Typography>

      </CardContent>
      <CardActions>
       <Button onClick={()=>{
          navigate('/addprescription',{state : {student : props.roll,check_student : props.student}})
        }} size="small" color="success" variant='contained'>Add New Presciption for this Student</Button>
        {/* <Link to="/prescriptionhistory"><Button sx={{mx:2}} size="small" color="secondary" variant='contained'>View past prescriptions</Button></Link> */}
      </CardActions>
    </Card>
        </>
    )
}

export default StudentinfoListItem;