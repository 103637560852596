import {Drawer,Box,List,ListItemIcon,ListItemText,ListItem,Divider,Typography} from '@mui/material';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MedicationIcon from '@mui/icons-material/Medication';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import {Link} from 'react-router-dom';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import TimerIcon from '@mui/icons-material/Timer';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import BedIcon from '@mui/icons-material/Bed';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import PersonIcon from '@mui/icons-material/Person';
function capitalizeString(str){
  if(str === null)
  return ""
  let str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2;
}


const ListData = {
  student : [
    {
      title : 'Book an Appointment',
      link : '/slotbook',
      icon : <MeetingRoomIcon/>
    },
    {
      title : 'View your past prescriptions',
      link : '/prescriptionhistory',
      icon : <MedicationIcon/>
    },
    {
      title : 'Check your Appointments',
      link : '/appointmenthistory',
      icon : <BookOnlineIcon/>
    }
    
  ],
  doctor : [
    {
      title : 'Search for Past Prescriptions of a Student',
      link : '/prescriptionsearch',
      icon : <ContentPasteSearchIcon/>
    },
    {
      title : 'Search and add New Prescription for a Student',
      link : '/searchstudent',
      icon : <AddIcon/>
    },
    {
      title : 'Add Prescription for the Guest',
      link : '/addguestprescription',
      icon : <AddIcon/>
    }
  ],
  clerk : [
    {
      title : 'Manage Medicine Stock',
      link : '/medicinestock',
      icon : <MedicalServicesIcon/>
    },
    {
      title : 'Dispense Medicine',
      link : '/dispensemedicine',
      icon : <LocalPharmacyIcon/>
    },
    // {
    //   title : 'Medicine Inventory Report',
    //   link : '/medicineinventory',
    //   icon : <CalendarViewWeekIcon/>
    // },
    // {
    //   title : 'Emergency Medicine Procurment Report',
    //   link : '/emergencymedicinereport',
    //   icon : <CoronavirusIcon/>
    // }
  
  ],
  report : [
    {
      title : 'Medicine Dispensed in a given Duration',
      link : '/searchformedicines',
      icon : <TimerIcon/>
    },
    {
      title : 'Problem Diagnosed in a specified Duration',
      link : '/problemdiagnosed',
      icon :  <CoronavirusIcon/>
    },
    {
      title : 'Students Visiting Healthcenter',
      link : '/studentvisit',
      icon : <LocalHospitalIcon/>
    },
    // {
    //   title : 'Students visting to given Doctor',
    //   link : '/studentvisitdoctor',
    //   icon : <BedIcon/>

    // },
    {
      title : 'Medicine Inventory Report',
      link : '/medicineinventory',
      icon : <CalendarViewWeekIcon/>
    },
    {
      title : 'View Emergency Cases',
      link : '/viewemergencycases',
      icon :  <CalendarViewWeekIcon/>
    },
    {
      title : 'Modify Doctor Slots',
      link : '/adminportal',
      icon :  <CalendarViewWeekIcon/>
    },
    {
      title : 'Guest Prescription Report',
      link : '/guestreport',
      icon : <BedIcon/>
    },
    {
      title : 'Individual Student Report',
      link : '/individualreport',
      icon : <PersonIcon/>
    }

  ],
  caretaker : [
    {
      title : 'Add Emergency Cases',
      link : '/emergencycases',
      icon : <BloodtypeIcon/>
    },
    {
      title : 'View Emergency Cases',
      link : '/viewemergencycases',
      icon :  <CalendarViewWeekIcon/>
    },
    {
      title : 'Add Prescription for the Guest',
      link : '/addguestprescription',
      icon : <AddIcon/>
    }
    
   
  ]
}

function renderList(list_data){
  return (
    list_data.map((elem,index)=>{
      return (
        <Link style={{textDecoration:'none',color:'black'}} to={elem.link}>
        <ListItem button key={index}>
        
           <ListItemIcon>
        {elem.icon}
      </ListItemIcon>
       <ListItemText  primary={elem.title} />

      </ListItem>
      </Link>
      )
    })
  )
}

const MenuDrawer = (props)=>{
    const handleClose = ()=>props.module(false)
    let identity = localStorage.getItem('type')

    return (
        <Drawer
        anchor={'left'}
        open={props.show}
        onClose={handleClose}
      >
           <Box
      sx={{ width: 250}}
      role="presentation"
      onClick={handleClose}
    >
      <List>
        <ListItem>
        <ListItemText><Typography variant="h6" fontWeight={"bold"}>{capitalizeString(identity)}</Typography></ListItemText>
        </ListItem>
        <Divider/>
        {identity==="student" || identity === "faculty" ?renderList(ListData.student):null}
        {identity==="doctor"?renderList(ListData.doctor):null}
        {identity==="clerk"?renderList(ListData.clerk):null}
        {identity === "admin"?renderList(ListData.report) : null}
        {identity === "caretaker"?renderList(ListData.caretaker) : null}
        
  
      </List>

    </Box>
          </Drawer>
    )
}

export default MenuDrawer;