import {Typography,Container,Divider,CircularProgress} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import {useEffect,useState} from 'react';
import axios from 'axios';
import {url} from '../constants';

const AppointmentHistory = ()=>{

    const [data, setData] = useState(null)

    useEffect(()=>{
      var config = {
        method: 'get',
        url: url + 'slots/history/',
        headers: { 
          'Authorization': `Token ${localStorage.getItem('token')}`, 
        },
      };
      
      axios(config)
      .then(function (response) {
        setData(response.data)
        console.log(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
      
    },[])


    return (
        <>
        <Container>
        <Typography variant="h4" fontWeight={"bold"} m={2}>Your Past and Upcoming Appointments</Typography>
        {data ===null?(<CircularProgress/>):(
           <Timeline position="alternate">
             {
               data.map((elem,index)=>{
                 if(index %2 === 0){
                  return (
                    <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      align="right"
                      variant="body2"
                      color="text.secondary"
                    >
                    {new Date(elem.date).toDateString()}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color="primary">
                        <AccessAlarmsIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <Divider/>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                      <Typography variant="h6" component="span">
                        Appointment
                      </Typography>
                      <Typography>With Doctor : {elem.doctor}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  )
                 }else{
                  return (
                    <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      {new Date(elem.date).toDateString()}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color="success">
                      <AccessAlarmsIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                      <Typography variant="h6" component="span">
                       Appointment
                      </Typography>
                      <Typography>With Doctor : {elem.doctor}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  )
                 }
               })
             }
        
     
         </Timeline>
        )}
       
        </Container>
        </>
    )
}

export default AppointmentHistory;