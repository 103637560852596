
import { Button,Typography,Container,TextField,FormControlLabel,Checkbox,
FormControl,InputLabel,Select,MenuItem} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddMedicineModal from '../components/addMedicineModal';
import {useState} from 'react';
import jsPDf from 'jspdf';
import 'jspdf-autotable';
import {useEffect} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';


let name = null, age = null, phone_number = null,report = "", tests = "", symptoms = "" , referred = "";
let follow_up = true, emergency = true;
let message = null , color = null;
let refferal_id = "";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
  
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function generate(temp,name,age, symptoms,referral,follow_up,report){
  
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
  
    const marginLeft = 40;
    const doc = new jsPDf(orientation, unit, size);
  
    doc.setFontSize(15);
  
    const title = "Guest Prescription";
    const headers = [["Sno.", "Name of the Medicine","Time of the day","Number of Days","Remarks"]];
    const data = temp.map((elem,index)=> [index+1, elem.name,elem.time_of_the_day,elem.days,elem.remarks]);
    let content = {
      startY: 250,
      head: headers,
      body: data
    };
    
    
    doc.text(title, marginLeft, 40);
    doc.autoTable({
      startY: 50,
      head: [
        ['Particulars', 'Value'],
      ],
      body: [
        ['Name: ',name],
        ['Age: ', age],
        ['Symptoms: ', symptoms],
        ['Referral' , referral],
        ['Follow Up' , follow_up],
        ['Report', report],
      
      ],
      theme: 'grid',
    });
  
    // doc.text(name, marginLeft, 40);
    // doc.text(rollnumber, marginLeft, doc.autoTable.);
    // doc.text(gender, marginLeft, 40);
    // doc.text(symptoms, marginLeft, 40);
    // doc.text(referral, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  
  }  


const AddGuestPrescription = ()=>{
    const [medicineData, setMedicineData] = useState([{
        name : 'Crocine',
        time_of_the_day : 'od',
        days : 12,
        qty : 12,
        remarks : 'ABC'
      }]);
      const [show, setShow] = useState(false);
      const [data1, setData1] = useState(null)
      const [valname, setvalname] = useState(false);
      const [valage, setvalage] = useState(false);
      const [valphone, setvalphone] = useState(false);
      const [show1, setShow1] = useState(false);
      const [disabled, setDisabled] = useState(false);
      const [type, setType] = useState(null);
      const [valtype, setvaltype] = useState(false);

      const handleChange = (event) => {
        setType(event.target.value);
      };

      
      useEffect(()=>{
        var config1 = {
            method: 'get',
            url: `${url}medicines/`,
            headers: { 
              'Authorization': `Token ${localStorage.getItem('token')}`, 
            }
          };
          
          axios(config1)
          .then(function (response) {
            setData1(response.data)
          })
          .catch(function (error) {
          });
      },[])

    return (
        <>
        <SnackBar1 show={show1} module={setShow1} message={message} color={color}/>
        {data1 !== null?(<AddMedicineModal medicinelist={data1} dataModule={setMedicineData} show={show} module={setShow}/>):null}
        <Typography variant="h4" sx={{m:2}} fontWeight={"bold"}>Add Prescription for Guest</Typography>
        <Container>
        <TextField error={valname} onChange={(e)=>name = e.target.value} sx= {{m:2,width:'800px'}} id="outlined-basic" label="Name" variant="outlined" />
        <br/>
        <TextField error={valage} onChange={(e)=>age = e.target.value} sx= {{m:2,width : '300px'}} id="outlined-basic" label="Age" variant="outlined" type="number" />
        <TextField error={valphone} onChange={(e)=>phone_number = e.target.value} sx= {{m:2,width : '500px'}} id="outlined-basic" label="Phone Number (+91)" variant="outlined" type="number" />
        <TextField onChange={(e)=>report = e.target.value} sx= {{m:2,width:'800px'}} id="outlined-basic" label="Report" variant="outlined" />
        <br/>
        <TextField onChange={(e)=>tests = e.target.value} sx= {{m:2,width:'800px'}} id="outlined-basic" label="Tests" variant="outlined" />
        <br/>
        <TextField onChange={(e)=>symptoms = e.target.value} sx={{m:2,width:'800px'}} id="outlined-basic" label="Symptoms" variant="outlined" />
        <br/>
        <TextField onChange={(e)=>referred = e.target.value} sx={{m:2,width:'800px'}} id="outlined-basic" label="Referred" variant="outlined" />
        <br/>
        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Referral Employee Type</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={type}
    label="Age"
    onChange={handleChange}
    error={valtype}
  >
    <MenuItem value={"Staff"}>Staff</MenuItem>
    <MenuItem value={"CMS Staff"}>CMS Staff</MenuItem>
    <MenuItem value={"Hostel Staff/Institute Workers"}>Hostel Staff/Institute Workers</MenuItem>
  </Select>
</FormControl>
        <br/>
        <TextField onChange={(e)=>refferal_id = e.target.value} sx={{m:2,width:'800px'}} id="outlined-basic" label="Referral ID" variant="outlined" />
        <br/>
        <FormControlLabel onChange={(e)=>follow_up = !follow_up} control={<Checkbox defaultChecked />} label="Follow Up" />
        <FormControlLabel onChange={(e)=>emergency = !emergency} control={<Checkbox defaultChecked />} label="Emergency" />
        </Container>

           
      <Container>


     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S No.</StyledTableCell>
            <StyledTableCell align="right">Name of the Medicine</StyledTableCell>
            <StyledTableCell align="right">Time of the Day</StyledTableCell>
            <StyledTableCell align="right">No. of Days</StyledTableCell>
            <StyledTableCell align="right">Qty</StyledTableCell>
            <StyledTableCell align="right">Remarks</StyledTableCell>
            <StyledTableCell align="right">Delete</StyledTableCell>
            
            
         
          </TableRow>
        </TableHead>
        <TableBody>
          {medicineData.map((row,index) => (
            <StyledTableRow key={row.name}>
                 <StyledTableCell component="th" scope="row">
                {index + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.time_of_the_day}</StyledTableCell>
              <StyledTableCell align="right">{row.days}</StyledTableCell>
              <StyledTableCell align="right">{row.qty}</StyledTableCell>
              <StyledTableCell align="right">{row.remarks}</StyledTableCell>
              <StyledTableCell align="right"><Button onClick={()=>{
                setMedicineData((prev)=>{
                  let newone = prev.filter((elem) =>{
                    return elem.name !==  row.name
                  })
                 
                  return newone

                })

              }} size="small" variant="contained" color="error">Delete</Button></StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button onClick={()=>{
      setShow(true)
    }} variant="contained" color="error" sx={{m:2}} >Add Medicine</Button>
    </Container>
    <Button disabled={disabled} onClick={()=>{
         let final_medicine_data = []
         for(var i = 0; i < medicineData.length; i++ ){
           let temp = {
             name : medicineData[i].name,
             quantity : medicineData[i].qty,
             days : medicineData[i].days, 
             remarks : medicineData[i].remarks, 
             choice : medicineData[i].time_of_the_day
           }
           final_medicine_data.push(temp);
         }
        
      if(name === null) setvalname(true);
      else setvalname(false)

      if(age === null) setvalage(true);
      setvalage(false);

      if(phone_number === null) setvalphone(true);
      else setvalphone(false);

      if(type === null) setvaltype(true)
      else setvaltype(false);

      if(name !== null && age !== null && phone_number !== null && type !== null){
        var data = JSON.stringify({
          "name": name,
          "age": age,
          "mobile_num": `+91 ${phone_number}`,
          "report": report,
          "follow_up": follow_up,
          "referred": referred,
          "emergency": emergency,
          "symptoms": symptoms,
          "tests": tests,
          "medicine":final_medicine_data,
          "referrer_id": refferal_id,
          "referred_by":type
        });
        
        var config = {
          method: 'post',
          url:`${url}guest/`,
          headers: { 
            'Authorization': `Token ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json', 
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          message = "Prescription Added!!!"
          color = "success"
          setShow1(true)
          setDisabled(true)
        })
        .catch(function (error) {
          message = "Network Error !! Pls Try Again"
          color = "error"
          setShow1(true)
        });
      }



    }} sx={{m:2}} variant="contained" color="success">Submit</Button>
        <Button onClick={()=>{
        generate(medicineData,name,age,symptoms,referred,follow_up,report)

        }} sx={{m:2}} variant="contained" color="error">Print</Button>
        </>
    )
}

export default AddGuestPrescription;