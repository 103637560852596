const url = "http://141.148.215.238/";

const slot_keys = {
  1: "9:00 AM - 10:00 AM",
  2: "10:00 AM - 11:00 AM",
  3: "11:00 AM - 12:00 PM",
  4: "12:00 PM - 1:00 PM",
  5: "1:00 PM - 2:00 PM",
  6: "2:00 PM - 3:00 PM",
  7: "3:00 PM - 4:00 PM",
  8: "4:00  PM - 5:00 PM",
  9: "5:00 PM - 6:00 PM",
  10: "6:00 PM - 7:00 PM",
};

export { url, slot_keys };
