import {Typography,Stack,TextField,Button,Container} from '@mui/material'
import {useState} from 'react';
import SnackBar1 from '../components/snackBar';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {url} from '../constants';


let  user = null, pass = null,color,message;


const AdminLogin = ()=>{
    const navigate = useNavigate()
    const [valuser, setvaluser] = useState(false);
    const [valpass, setvalpass] = useState(false);
    const [show, setShow] = useState(false);

    return (
        <>
        <SnackBar1 show={show} color={color} message={message}/>
        <Typography variant="h4" sx={{m:2,fontWeight:"bold"}}>Admin Login</Typography>
        <Container>
        <form>
        <Stack m={2} spacing={3}>
          <TextField error={valuser} onChange={(e)=>user = e.target.value} label="User ID" type="email"/>
          <TextField error={valpass}  onChange={(e)=>pass = e.target.value} label="Password" type="password" />
  
         
        </Stack>
      </form>
     <Button variant="contained" onClick={()=>{
            if(user === null) setvaluser(true)
            if(pass === null) setvalpass(true)
            if(user !== null && pass !== null){

              var data = JSON.stringify({
                "username": user,
                "password": pass,
              });
              
              var config = {
                method: 'post',
                url: `${url}accounts/login/`,
                headers: { 
                  'Content-Type': 'application/json', 
                },
                data : data
              };

                        axios(config)
          .then(function (response) {
            localStorage.setItem('token',response.data.token)
            localStorage.setItem('type','admin')
            navigate('/adminportal')
          })
          .catch(function (error) {
              message = "There was some error !!"
              color = "error"
              setShow(true)
          });
              
            }
        
  
  
          }}>
            Submit
          </Button>
          </Container>
          </>
    )
}


export default AdminLogin;