import {Table,TableRow,Container,Button,Typography,CircularProgress,Fab} from '@mui/material';
import {Refresh} from '@mui/icons-material';
import TableCell ,{ tableCellClasses }from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import BookSlotModal from '../components/bookSlotModal';
import {useState,useEffect} from 'react';
import axios from 'axios'
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';
import ColorCodingInfo from '../components/colorCodingInfo';


let message = null, color = null

let  slot_pass = null, date_pass = null,slot_id = null
let doctor_list = []
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const SlotBooking = ()=>{

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
 
    const [tabledata, settableData] = useState(null)
    const [search,setSearch] = useState(false)
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false);

    
    useEffect(()=>{
    
      var config = {
        method: 'get',
        url: url + 'doctors/',
        headers: { 
        }
      };
      
      axios(config)
      .then(function (response) {
      
        setData(response.data)
        setSearch(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    },[])

    return (
        <>

        <SnackBar1 show={show1} module={setShow1} message={message} color={color}/>
      <ColorCodingInfo show={show2} module={setShow2}/>
    <BookSlotModal module={setShow} show={show} doctor_list={doctor_list} slot={slot_pass} date={date_pass} slotid={slot_id}/>
            <Typography sx={{m:2,fontWeight : 'bold'}} variant="h4"> Book a Slot </Typography>

    {data === null?<CircularProgress sx={{m:5}}/>:(
       <Container>
               
<Button onClick={()=>{


    var config = {
      method: 'get',
      url: url + `slots/`,
      headers: { 
        'Authorization': `Token ${localStorage.getItem('token')}`, 
        'Content-Type': 'application/json', 
      },


    };
    axios(config)
    .then(function (response) {
      console.log(response.data)
      settableData(response.data)
      setSearch(false)
      message = "Successfully Loaded the slots!!"
      color = "success"
      setShow1(true)

    })
    .catch(function (error) {
      setSearch(false)
      message = "There was some error"
      color = "error"
      setShow1(true)
    });
  


}} variant="contained" color="error" sx={{m:5}}>Load Slots</Button>

{search === true?<CircularProgress/>:null}
{!search && tabledata !== null?(
   <>
  <Button onClick={()=>setShow2(true)} variant="contained" color="secondary">Check Color Coding</Button>
 <Table responsive sx={{m: 2}}>

 <StyledTableRow>
 <TableCell sx={{fontWeight:'bold',backgroundColor:'black',color:'white'}}>Date</TableCell>
    <TableCell variant="head" sx={{fontWeight:'bold',backgroundColor:'black'}}></TableCell>
    {tabledata[Object.keys(tabledata)[0]].map((elem)=>{
      return (<TableCell sx={{fontWeight:'bold',backgroundColor:'black',color:'white',width:'100px'}}>{elem.slot}</TableCell>)

    })}

 
 </StyledTableRow>

 {Object.keys(tabledata).map((elem)=>{
   return (
    <StyledTableRow>
    <StyledTableCell variant="head" sx={{fontWeight:'bold'}}>{new Date(elem).toDateString()}</StyledTableCell>
    <StyledTableCell variant="head" sx={{fontWeight:'bold'}}></StyledTableCell>
    {tabledata[elem].map((el,index)=>{
      return (
        <StyledTableCell><Button onClick={()=>{
   
          for(var i = 0; i < tabledata[elem].length; i ++){
            if(el.slot === tabledata[elem][i].slot){
              doctor_list = tabledata[elem][i].doctor_list
            }
          }
          date_pass = new Date(elem)
          // doctor_pass = doctor
          slot_pass = el.slot
          slot_id = index
        
          setShow(true)}} variant="contained">Check</Button></StyledTableCell>

      )
    })}
  
 </StyledTableRow>
   )
 })}
 
 </Table>
</>
): null}
   <Fab 
   onClick={()=>{
   
   
     
       setSearch(true)
   
       var config = {
         method: 'get',
         url: url + 'slots/',
         headers: { 
           'Authorization': `Token ${localStorage.getItem('token')}`, 
           'Content-Type': 'application/json', 
         },
       
   
       };
       axios(config)
       .then(function (response) {
         settableData(response.data)
         setSearch(false)
         message = "Successfully Loaded the slots!!"
         color = "success"
         setShow1(true)
   
       })
       .catch(function (error) {
         setSearch(false)
         message = "There was some error"
         color = "error"
         setShow1(true)
       });
     
   
   }}
   style={{
       margin: 0,
       top: 'auto',
       right: 20,
       bottom: 20,
       left: 'auto',
       position: 'fixed',
       zIndex : 1
    }}color="primary" aria-label="add">
 <Refresh />
</Fab>
</Container>

    )}
  
  
        </>
    )
}

export default SlotBooking;