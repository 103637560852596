import {Typography,TextField,Button} from '@mui/material';
import TableCell ,{ tableCellClasses }from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import {useState} from 'react';
import TableRow from '@mui/material/TableRow';
import SnackBar1 from '../components/snackBar';
import {url} from '../constants';
import axios from 'axios';
import jsPDf from 'jspdf';
import 'jspdf-autotable';
let start_date = null , end_date = null;


let message = null, color = null;


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 17,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
   
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function generate(temp){
  
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
  
    const marginLeft = 40;
    const doc = new jsPDf(orientation, unit, size);
  
    doc.setFontSize(15);
  
    const title = "Medicine Dispense Stats";
   
    const headers = [["Sno.", "Name of the Student","Date","Doctor"]];
    const data = temp.map((row,index)=> [index+1, row.student, row.date , row.doctor]);
    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    
    
    doc.text(title, marginLeft, 40);
  
    doc.autoTable(content);
    doc.save("report.pdf")

  }
  


const StudentVisit = ()=>{

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [load, setLoad] = useState(false);
    const [valstartdate, setvalstartdate] = useState(false);
    const [valenddate , setvalenddate] = useState(false);
    const [data,setData] = useState(null);

    return (
        <>
        <SnackBar1 show={show1} module={setShow1} message={message} color={color}/>
        <Typography variant="h4" fontWeight={"bold"} my={2}>Students visiting to the Clinic in Specified Duration</Typography>
        <TextField onChange={(e)=>start_date = e.target.value} error={valstartdate} type="date" sx={{m:2}} label="Start Date" InputLabelProps={{ shrink: true }}/>
        <TextField onChange={(e)=>end_date = e.target.value}   error={valenddate} type="date" sx={{m:2}} label="End Date" InputLabelProps={{ shrink: true }}/>
        <Button variant="contained" color="error" sx={{m:2}} onClick={()=>{
          if(start_date === null) setvalstartdate(true)
          else setvalstartdate(false)

          if(end_date === null) setvalenddate(true)
          else setvalenddate(false)

          if(start_date !== null && end_date !== null){
            setLoad(true)
            var config = {
              method: 'get',
              url: url + `studentvis/?start_date=${new Date(start_date).toISOString()}&end_date=${new Date(end_date).toISOString()}`,
              headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Token ${localStorage.getItem('token')}`

              },
           
            };
            
            axios(config)
            .then(function (response) {
              message = "Successfully Added!!"
              color = "success"
              setLoad(false);
              setData(response.data)
              setShow1(true)
            })
            .catch(function (error) {
              message = "Failure !!"
              color = "error"
              setShow1(true)
              setLoad(false);
            });

          }

            setShow(true)
        }}>Search</Button>
        {show && data?(
            <>
             <Container sx={{my:2}}>
               <TableContainer component={Paper}>
             <Table sx={{ minWidth: 700 }} aria-label="customized table">
               <TableHead>
                 <TableRow>
                   <StyledTableCell >S No.</StyledTableCell>
                   <StyledTableCell align="right" sx={{fontSize:'19px'}}>Name of the Student</StyledTableCell>
                   <StyledTableCell align="right" sx={{fontSize:'19px'}}>Date</StyledTableCell>
                   <StyledTableCell align="right" sx={{fontSize:'19px'}}>Doctor</StyledTableCell>
          
                 </TableRow>
               </TableHead>
               <TableBody>
                 {data.map((row,index) => (
                   <StyledTableRow key={index}>
                     <StyledTableCell component="th" scope="row">
                       {index + 1}
                     </StyledTableCell>
                     <StyledTableCell align="right" sx={{fontSize:'15px'}}>{row.student}</StyledTableCell>
                     <StyledTableCell align="right" sx={{fontSize:'15px'}}>{new Date(row.date).toDateString()}</StyledTableCell>
                     <StyledTableCell align="right" sx={{fontSize:'15px'}}>{row.doctor}</StyledTableCell>
                   </StyledTableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
           <Button sx={{m:2}} onClick={()=>{
              generate(data)
           }} variant="contained" color="error">Print</Button>
           </Container>
            </>
        ) : null}
         
        </>
    )
}

export default StudentVisit;