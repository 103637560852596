import {Typography,Container,TextField,Button,FormControlLabel,Checkbox} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddMedicineModal from '../components/addMedicineModal';
import {useLocation} from 'react-router-dom';
import {useState} from 'react';
import jsPDf from 'jspdf';
import 'jspdf-autotable';
import {useEffect} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';


let message = null, color = null;

function generate(temp,name,age, symptoms,referral){
  
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDf(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Prescription";
  const headers = [["Sno.", "Name of the Medicine","Time of the day","Number of Days","Remarks"]];
  const data = temp.map((elem,index)=> [index+1, elem.name,elem.time_of_the_day,elem.days,elem.remarks]);
  let content = {
    startY: 250,
    head: headers,
    body: data
  };
  
  
  doc.text(title, marginLeft, 40);
  doc.autoTable({
    startY: 50,
    head: [
      ['Particulars', 'Value'],
    ],
    body: [
      ['Name: ',name],
      ['Age: ', age],
      ['Symptoms: ', symptoms],
      ['Referral' , referral],
    ],
    theme: 'grid',
  });

  // doc.text(name, marginLeft, 40);
  // doc.text(rollnumber, marginLeft, doc.autoTable.);
  // doc.text(gender, marginLeft, 40);
  // doc.text(symptoms, marginLeft, 40);
  // doc.text(referral, marginLeft, 40);
  doc.autoTable(content);
  doc.save("report.pdf")

}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,

  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const AddPrescription = () => {
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null)
  const [show1, setShow1] = useState(false);


  const [symptoms, setSymptoms] = useState(null)
  const [referral, setReferral] = useState(null)
  const [follow, setFollow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  
  const history = useLocation()

    useEffect(()=>{

      var config1 = {
        method: 'get',
        url: `${url}medicines/`,
        headers: { 
          'Authorization': `Token ${localStorage.getItem('token')}`, 
        }
      };
      
      axios(config1)
      .then(function (response) {
        setData1(response.data)
      })
      .catch(function (error) {
      });
      var config  = {}
      if(history.state.check_student){
      config = {
        method: 'get',
        url: `${url}accounts/student/info/?student=${history.state.student}`,
        headers: { 
          'Authorization': `Token ${localStorage.getItem('token')}`, 
        }
      };
    }
      else {

        config = {
          method: 'get',
          url: `${url}accounts/faculty/info/?college_id=${history.state.student}`,
          headers: { 
            'Authorization': `Token ${localStorage.getItem('token')}`, 
          }
        };
      }
      
      axios(config)
      .then(function (response) { 

        setData(response.data);
        message = "Student's Information Loaded"
        color = "success"

        setShow1(true)

      })
      .catch(function (error) {
        message = "There was some Error"
        color = "error"
        setShow1(true)
      });

    },[])

    const [show, setShow] = useState(false);
    const [medicineData, setMedicineData] = useState([{
      name : 'Crocine',
      time_of_the_day : 'od',
      days : 12,
      qty : 12,
      remarks : 'ABC'
    }]);


    return (
      <>
      <SnackBar1 module={setShow1} color={color} show={show1} message={message}/>
      {data1 !== null?(<AddMedicineModal medicinelist={data1} dataModule={setMedicineData} show={show} module={setShow}/>):null}
      <Typography variant="h4" fontWeight={"bold"} sx={{m:2}}>Add Student's Prescription</Typography>
      <Typography variant="h5" align={"left"} sx={{marginTop:'20px',marginBottom:'20px',marginLeft:'80px',fontWeight:"bold",textDecoration:'underline'}}>Student Details: </Typography>

      <Container sx={{my:5}}>
      {data !== null && history.state.check_student? (
         <TableContainer component={Paper}>
         <Table sx={{minWidth:700}}>
       <StyledTableRow>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">Name of the Student</StyledTableCell>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}}>{data.name}</StyledTableCell>
       </StyledTableRow>
       <StyledTableRow>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">Age</StyledTableCell>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}}>{data.age}</StyledTableCell>
       </StyledTableRow>
       <StyledTableRow>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">Roll Number</StyledTableCell>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}} >{data.college_id}</StyledTableCell>
       </StyledTableRow>
       <StyledTableRow>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">Branch</StyledTableCell>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}}>{data.branch}</StyledTableCell>
       </StyledTableRow>
       <StyledTableRow>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">Hostel and Room Number</StyledTableCell>
           <StyledTableCell style={{fontWeight:'bold',fontSize:18}} >{data.hostel} - {data.room_num}</StyledTableCell>
       </StyledTableRow>
       </Table>
   </TableContainer>
      ): null}

      {data !== null && !history.state.check_student? (
            <TableContainer component={Paper}>
            <Table sx={{minWidth:700}}>
          <StyledTableRow>
              <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">Name of the Faculty</StyledTableCell>
              <StyledTableCell style={{fontWeight:'bold',fontSize:18}}>{data.name}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">Age</StyledTableCell>
              <StyledTableCell style={{fontWeight:'bold',fontSize:18}}>{data.age}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell style={{fontWeight:'bold',fontSize:18}} variant="head">College ID</StyledTableCell>
              <StyledTableCell style={{fontWeight:'bold',fontSize:18}} >{data.college_id}</StyledTableCell>
          </StyledTableRow>
       
          </Table>
      </TableContainer>
      ):null}
    
    </Container>


    <Typography variant="h5" align={"left"} sx={{marginTop:'20px',marginBottom:'20px',marginLeft:'80px',fontWeight:"bold",textDecoration:'underline'}}>Non - mandatory Fields</Typography>

    <div>
          <TextField
          onChange={(e)=>setSymptoms(e.target.value)}
            label="Symptoms (Optional)"
            sx={{width:500,mx:2}}
          />

          <TextField
          onChange={(e)=>setReferral(e.target.value)}
            label="Referral (Optional)"
            sx={{width:300,mx: 2}}
          />


<FormControlLabel control={<Checkbox onChange={(e)=>setFollow((prev) =>!prev)} />} label="Follow Up" />

        </div>
    

      <Typography variant="h5" align={"left"} sx={{marginTop:'20px',marginBottom:'20px',marginLeft:'80px',fontWeight:"bold",textDecoration:'underline'}}>Medicines Prescribed (If any) </Typography>
      
      
      
      <Container>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S No.</StyledTableCell>
            <StyledTableCell align="right">Name of the Medicine</StyledTableCell>
            <StyledTableCell align="right">Time of the Day</StyledTableCell>
            <StyledTableCell align="right">No. of Days</StyledTableCell>
            <StyledTableCell align="right">Qty</StyledTableCell>
            <StyledTableCell align="right">Remarks</StyledTableCell>
            <StyledTableCell align="right">Delete</StyledTableCell>
            
            
         
          </TableRow>
        </TableHead>
        <TableBody>
          {medicineData.map((row,index) => (
            <StyledTableRow key={row.name}>
                 <StyledTableCell component="th" scope="row">
                {index + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.time_of_the_day}</StyledTableCell>
              <StyledTableCell align="right">{row.days}</StyledTableCell>
              <StyledTableCell align="right">{row.qty}</StyledTableCell>
              <StyledTableCell align="right">{row.remarks}</StyledTableCell>
              <StyledTableCell align="right"><Button onClick={()=>{
                setMedicineData((prev)=>{
                  let newone = prev.filter((elem) =>{
                    return elem.name !==  row.name
                  })
                 
                  return newone

                })

              }} size="small" variant="contained" color="error">Delete</Button></StyledTableCell>



            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button onClick={()=>{
      setShow(true)
    }} variant="contained" color="error" sx={{m:2}} >Add Medicine</Button>
    </Container>
    
    <Button disabled={disabled} onClick={()=>{

        let final_medicine_data = []
        for(var i = 0; i < medicineData.length; i++ ){
          let temp = {
            name : medicineData[i].name,
            quantity : medicineData[i].qty,
            days : medicineData[i].days, 
            remarks : medicineData[i].remarks, 
            choice : medicineData[i].time_of_the_day
          }
          final_medicine_data.push(temp);
        }

        var data1 = JSON.stringify({
          "prescription": "t1",
          "college_id": data.college_id,
          "report": "t1",
          "follow_up": follow,
          "referred": referral,
          "symptoms": symptoms,
          "tests": "dlkfhsdkfhjdskfhkdsjf",
          "medicine": final_medicine_data,
          
        });
        
        var config = {
          method: 'post',
          url: url + 'prescriptions/',
          headers: { 
            'Authorization': `Token ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json', 
          },
          data : data1
        };
        
        axios(config)
        .then(function (response) {
          message = "Prescription Added!!!"
          color = "success"
          setShow1(true)
          setDisabled(true)
        })
        .catch(function (error) {
          message = "Network Error !! Pls Try Again"
          color = "error"
          setShow1(true)
        });




    }} style={{position:'absolute',left:100}} variant="contained" color="success" size={"large"}>Save</Button>
    <Button onClick={()=>{
      generate(medicineData,data.name,data.age,symptoms,referral)
    }} style={{position:'absolute',left:100}} sx={{mx:12}}variant="contained" color="error" size={"large"}>Print</Button>
    
      </>
    )


  
 

}

export default AddPrescription;