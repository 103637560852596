import {Button, Modal , Box, Typography,Stack,TextField, CircularProgress} from '@mui/material';
import {useState} from 'react';
import axios from 'axios';
import SnackBar1 from '../components/snackBar';
import {url} from '../constants';

let name = null, username = null, id = null, email = null, mobile = null, password = null, retype = null

let message = null, color = null

const ClerkRegistrationModal = (props)=>{


    const [valname, setvalname] = useState(false)
    const [valid, setvalid] = useState(false);
    const [valemail, setvalemail] = useState(false)
    const [valphone, setvalphone] = useState(false);
    const [valpass, setvalpass] = useState(false);
    const[valretype , setvalretype] = useState(false);
    const [valusername, setvalusername] = useState(false);
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);

    const handleClose = ()=>props.module(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    return (
        <>
        <SnackBar1 show={show} module={setShow} color={color} message={message} />
        <Modal
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
    <Box sx={style}>
        <Typography fontWeight={"bold"} id="modal-modal-title" variant="h5" component="h2">
        Clerk Registration
        </Typography>
        <form>
      <Stack m={2} spacing={3}>
      <TextField error={valname} onChange={(e)=>name = e.target.value} label="Name of Clerk" type="text"/>
      <TextField error={valusername} onChange={(e)=>username = e.target.value} label="Username" type="text"/>

      <TextField error={valid} onChange={(e)=>id = e.target.value} label="College ID" type="text"/>
      <TextField error={valemail} onChange={(e)=>email = e.target.value} label="Email" type="email"/>
      <TextField error={valphone} onChange={(e)=>mobile = e.target.value} label="Mobile Number(+91)" type="number"/>
      
        <TextField error={valpass} onChange={(e)=>password = e.target.value} label="Password" type="password" />
        <TextField error={valretype} onChange={(e)=>retype = e.target.value} label="Retype Password" type="password" />

       
      </Stack>
    </form>
    <Button variant="contained" onClick={()=>{
          if(name === null) setvalname(true)
          else setvalname(false)

          if(id === null) setvalid(true)
          else setvalid(false)

          if(email === null) setvalemail(true)
          else setvalemail(false)

          if(mobile === null) setvalphone(true)
          else setvalphone(false);

          if(password === null) setvalpass(true)
          else setvalpass(false);
          
          if(retype === null) setvalretype(true)
          else setvalretype(false);

            if(username === null) setvalusername(true)
            else setvalusername(false);

            if(password !== retype) {
              setvalpass(true)
              setvalretype(true)
            }else{
              setvalretype(false);
              setvalretype(false);
            }


          if(username !== null && name !== null && id !== null && email !== null && mobile !== null && password !== null && retype !== null && password === retype){
            setLoad(true)
            var data = JSON.stringify({
              "username": username,
              "password": password,
              "is_student": false,
              "is_doctor": false,
              "is_clerk": true,
              "name":name,
              "mobile_num": "+91 " + mobile,
              "college_id": id,
              "email": email,
              "uid" : id
            });
            
            var config = {
              method: 'post',
              url: url + 'accounts/signup/',
              headers: { 
                'Content-Type': 'application/json', 
              },
              data : data
            };
            
            axios(config)
            .then(function (response) {
              message = "Successfully Registered !!";
              color = "success"
              setShow(true)
              setLoad(false)
              handleClose()
            })
            .catch(function (error) {
              message = "User Already Exists !!"
              color = "error"
              setShow(true);
              setLoad(false);
              handleClose()
            });
          }


        }}>
          Submit
        </Button>
        <Button sx={{m:2}} color="error" variant="contained" onClick={()=>{
           props.module(false)
        }}>
          Close
        </Button>
        <br/>
        {load?<CircularProgress/>: null}
    </Box>
    </Modal>
        </>
    )
}

export default ClerkRegistrationModal; 