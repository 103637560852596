import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import { FormLabel } from '@mui/material';
import {useState} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';

let message = null, color = null;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

let start_date = null, end_date = null,time = "Morning"
const AddDoctorSchedule = (props)=>{

    const handleClose = ()=>{props.module(false)}
    const [valstart, setvalstart] = useState(false);
    const [valend, setvalend] = useState(false);
    const [show1, setShow1] = useState(false);


    return (
      <>
      <SnackBar1 show={show1} module={setShow1} color={color} message={message}/>
        <Modal
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           Add Doctor Scheduled Slot Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <RadioGroup onChange={(e)=>time = e.target.value} name="use-radio-group" defaultValue="Morning">
      <FormControlLabel value="Morning" label="Morning" control={<Radio />} />
      <FormControlLabel value="Evening" label="Evening" control={<Radio />} />
    </RadioGroup>
            </Typography>
            <br/>
            <FormLabel > Start Date </FormLabel>
            <TextField error={valstart} onChange={(e)=>start_date = e.target.value} sx={{m:2}} type="date"/>
            <br/>
            <FormLabel > End Date </FormLabel>
            <TextField error={valend} onChange={(e)=>end_date = e.target.value} sx={{m:2}} type="date"/>
            <Button variant="contained" onClick={()=>{
          if(start_date === null) setvalstart(true)
          else setvalstart(false);

          if(end_date === null) setvalend(true)
          else setvalend(false);

          if(start_date !== null && end_date !== null){
            var data = JSON.stringify({
              "start": start_date,
              "end": end_date,
              "doctor": props.doctor,
              "morning": time==="Morning",
              "evening": time==="Evening",
            });
            
            var config = {
              method: 'post',
              url: `${url}doctor/duration/`,
              headers: { 
                'Content-Type': 'application/json', 
              },
              data : data
            };
            
            axios(config)
            .then(function (response) {
              message = "Successfull"
              color = "success"
              setShow1(true)
              handleClose()

            })
            .catch(function (error) {
              message = "failure"
              color = "error"
              setShow1(true)
              handleClose()
            });
          }

        }}>Save</Button>
        </Box>
       
      </Modal>
      </>
    )
}

export default AddDoctorSchedule;