import {Container, TextField,Typography,Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';
import jsPDf from 'jspdf';
import 'jspdf-autotable';


let message = null, color = null;
let start_date = null, end_date = null;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  


  function generate(temp){
  
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
  
    const marginLeft = 40;
    const doc = new jsPDf(orientation, unit, size);
  
    doc.setFontSize(15);
  
    const title = "Emergency Cases Report for Students";
   
    const headers = [["Sno.", "Name of the Student","Roll Number","Symptoms","Referral(If any)","Date"]];
    const data = temp.map((row,index)=> [index+1, row.student_name, row.roll_number,row.symptoms,row.referred,new Date(row.date).toDateString()]);
    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    
    
    doc.text(title, marginLeft, 40);
  
    doc.autoTable(content);
    doc.save("report.pdf")

  }
  



const ViewEmergencyCases = ()=>{


    const [data,setData] = useState(null)
    const [valstart, setvalstart] = useState(false);
    const [valend, setvalend] = useState(false);
    const [show, setShow] = useState(false);


    useEffect(()=>{
     
    },[])

    return (
        <>
        <SnackBar1 show={show} module={setShow} color={color} message={message}/>
        <Container sx={{mx:5,my:5}}>
            <Typography variant="h4" fontWeight="bold"  sx={{textAlign:'center'}}>View Emergency Cases of Past in Specified Duration</Typography>
            <TextField error={valstart} onChange={(e)=>start_date = e.target.value} type="date"  sx={{m:2}} />
            <TextField error={valend}   onChange={(e)=>end_date = e.target.value} type="date"  sx={{m:2}}/>
            <Button onClick={()=>{
              if(start_date === null) setvalstart(true)
              else setvalstart(false);

              if(end_date === null) setvalend(true)
              else setvalend(false);

              if(start_date !== null && end_date !== null){
                var config = {
                  method: 'get',
                  url: `${url}studentEmrgencyCases?start_date=${new Date(start_date).toISOString()}&end_date=${new Date(end_date).toISOString()}`,
                  headers: { 
                    'Authorization': `Token ${localStorage.getItem('token')}`

                  }
                };
                
                axios(config)
                .then(function (response) {
                  setData(response.data)
                  message = "Data loaded!!"
                  color = "success"
                  setShow(true);
                })
                .catch(function (error) {
                  message = "There was some Error"
                  color = "error"
                  setShow(true);
                });

              }  

            }} variant="contained" color="error" sx={{m:2}}>Search</Button>
        
        
            {data !== null?(
               <>
                <TableContainer component={Paper} sx={{m:5}}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>S No.</StyledTableCell>
                      <StyledTableCell align="right">Name of the Student</StyledTableCell>
                      <StyledTableCell align="right">Roll Number</StyledTableCell>
                      <StyledTableCell align="right">Symptoms</StyledTableCell>
                      <StyledTableCell align="right">Referral(If any)</StyledTableCell>
                      <StyledTableCell align="right">Date</StyledTableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row,index) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.student_name}</StyledTableCell>
                        <StyledTableCell align="right">{row.roll_number}</StyledTableCell>
                        <StyledTableCell align="right">{row.symptoms}</StyledTableCell>
                        <StyledTableCell align="right">{row.referred}</StyledTableCell>
                        <StyledTableCell align="right">{new Date(row.date).toDateString()}</StyledTableCell>
          
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button variant="contained" color="error" onClick={()=>generate(data)}>Print</Button>
             </>
            ) : null}
          
        </Container>



        </>
    )
}

export default ViewEmergencyCases;