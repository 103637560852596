import {Typography,TextField,Button,Container} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jsPDf from 'jspdf';
import 'jspdf-autotable';
import {useState} from 'react';
import axios from 'axios';
import {url} from '../constants';
import SnackBar1 from '../components/snackBar';


let start_date = null,end_date = null;
let message = null, color = null;

function generate(temp){
  
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDf(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Medicine Inventory Report";
 
  const headers = [["Sno.", "Date of Issue","Medicine Name","Qty","Cost Per Unit","Manufacturing Date","Expiry Date","Issued From"]];
  const data = temp.map((elem,index)=> [index+1, elem.date_in,elem.name,elem.quantity,elem.cost_per_piece,elem.manufacturing_date,elem.expiry,elem.seller]);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  
  
  doc.text(title, marginLeft, 40);

  doc.autoTable(content);
  doc.save("report.pdf")

}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  


  

const MedicineInventoryStatus = ()=>{

    const [data, setData] = useState(null)
    const [show, setShow] = useState(false);
    const [valstart, setvalstart] = useState(false);
    const [valend, setvalend] = useState(false);
   

    return (
        <>
        <SnackBar1 show={show} module={setShow} message={message} color={color}/>
        <Typography variant="h4" sx={{m:2}} fontWeight={"bold"}>Medicine Inventory Report</Typography>        
        <TextField error={valstart} onChange={(e)=>start_date = e.target.value} id="outlined-basic" sx={{mx:1}} variant="outlined" type="date"/>
        <TextField error={valend} onChange={(e)=>end_date = e.target.value} id="outlined-basic" sx={{mx:1}} variant="outlined" type="date" />
        <Button onClick={()=>{
          
          if(start_date === null) setvalstart(true)
          else setvalstart(false);

          if(end_date === null) setvalend(true)
          else setvalend(false);

          if(start_date !== null && end_date !== null){
           
            
            var config = {
              method: 'get',
              url: `${url}report/?start_date=${new Date(start_date).toISOString()}&end_date=${new Date(end_date).toISOString()}`,
              headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Token ${localStorage.getItem('token')}`

              },
            };
            
            axios(config)
            .then(function (response) {
                setData(response.data)
                message = "Data Loaded!!"
                color = "success"
                setShow(true)
            })
            .catch(function (error) {
              console.log(error);
              message = "There was some Error !!"
              color = "error"
              setShow(true)
            });
          }

       
        }} variant="contained" sx={{m:1}} color="error">Search</Button>


        <Container sx={{my:2}}>
          {data !== null ? (
             <TableContainer component={Paper}>
             <Table sx={{ minWidth: 700 }} aria-label="customized table">
               <TableHead>
                 <TableRow>
                   <StyledTableCell>S No.</StyledTableCell>
                   <StyledTableCell align="right">Date of Issue</StyledTableCell>
                   <StyledTableCell align="right">Medicine Name</StyledTableCell>
                   <StyledTableCell align="right">Qty.</StyledTableCell>
                   <StyledTableCell align="right">Cost per Unit</StyledTableCell>
                   <StyledTableCell align="right">Manufacturing Date</StyledTableCell>
                   <StyledTableCell align="right">Expiry Date</StyledTableCell>
                   <StyledTableCell align="right">Issued from</StyledTableCell>
       
                 </TableRow>
               </TableHead>
               <TableBody>
                 {data.medicines_purchased.map((row,index) => (
                   <StyledTableRow key={row.index}>
                   <StyledTableCell align="right">{index + 1}</StyledTableCell>
                     <StyledTableCell component="th" scope="row">
                       {new Date(row.date_in).toLocaleDateString()}
                     </StyledTableCell>
       
                     <StyledTableCell align="right">{row.name}</StyledTableCell>
                     <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                     <StyledTableCell align="right">{row.cost_per_piece}</StyledTableCell>
                     <StyledTableCell align="right">{row.manufacturing_date}</StyledTableCell>
                     <StyledTableCell align="right">{row.expiry}</StyledTableCell>

                     <StyledTableCell align="right">{row.seller}</StyledTableCell>
       
                   </StyledTableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
          ) : null}
       
    <Button onClick={()=>{
      generate(data.medicines_purchased)
    }} variant="contained" color="success" sx={{m:2}}>Print</Button>
    </Container>
        </>
    )
}

export default MedicineInventoryStatus;